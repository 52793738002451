import React from 'react';
import { useSubscription } from '@apollo/client';
import { cardClient } from '../../../../apolloClient';
import { ON_CARD_CREATE_OR_UPDATE } from '../../../../graphql/graphql_card_linkcy/card/subscription';
import {
  OnCardCreateOrUpdate,
  OnCardCreateOrUpdateVariables,
} from '../../../../graphql/graphql_card_linkcy/card/types/OnCardCreateOrUpdate';
import { useCreateOrUpdateCardCache } from '../../../customHooks/card/createOrUpdateCardCache';

const CardSub = (props: { ledgerLinkcyId: string }) => {
  const { ledgerLinkcyId } = props;
  const { createOrUpdateCardLinkcy } = useCreateOrUpdateCardCache();
  useSubscription<OnCardCreateOrUpdate, OnCardCreateOrUpdateVariables>(
    ON_CARD_CREATE_OR_UPDATE,
    {
      variables: {
        ledgerLinkcyId,
      },
      client: cardClient,
      onSubscriptionData: ({ subscriptionData: { data } }) => {
        if (data?.onCardCreateOrUpdate) {
          createOrUpdateCardLinkcy(data.onCardCreateOrUpdate);
        }
      },
    },
  );

  return <></>;
};

export default CardSub;
