export default {
  'mainTeam.error.getRoles': `Error while getting roles list.`,
  'mainTeam.error.getMembers': `Error while getting members list.`,
  'mainTeam.error.addMember': `Error while creating new member.`,
  'mainTeam.error.memberLimitReached': 'Please change offer to get more member',
  'mainTeam.error.sendInvitationMail': `Error while sending mail to new member. Please resend`,
  'mainTeam.error.updateMember': `Error while updating member.`,
  'mainTeam.error.deleteUser': `Error while deleting user.`,
  'mainTeam.success.addMember': `Member created successfully.`,
  'mainTeam.success.updateMember': `Member updated successfully.`,
  'mainTeam.success.sendInvitationMail': `An invitation mail was sent to new member.`,
  'mainTeam.success.deleteUser': `User deleted successfully.`,
  'mainTeam.dialogTitle.createMember': `Member creation`,
  'mainTeam.dialogTitle.editMember': `Edit member`,
  'mainTeam.label.addMember': 'Add member',
  'mainTeam.label.searchMember': 'Search member',
  'mainTeam.label.authorizations': 'Authorizations',
  'mainTeam.label.authorizationsInfo': `Select a member to display assigned rights.`,
  'mainTeam.error.notAllowAddMember': 'no longer allows you to add a member.',
  'mainTeam.label.reinvite': 'Invite',
  'team.filter.team': 'Team',
  'team.filter.role': 'Role',
  'team.filter.state': 'State',
  'team.filter.title': 'Team Filter',
  'team.filter.subtitle': 'Filter your teams',
  'team.filter.paragraph': 'See your team with your criteria and parameter',
};
