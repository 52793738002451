import gql from 'graphql-tag';
import { LEDGER_FRAGMENT } from './fragment';

export const ON_BALANCE_UPDATE = gql`
  subscription OnBalanceUpdate($corporateLinkcyId: String) {
    onBalanceUpdate(corporateLinkcyId: $corporateLinkcyId) {
      ...LegderInfo
    }
  }
  ${LEDGER_FRAGMENT}
`;
