import gql from 'graphql-tag';
import { INFORMATION_FRAGMENT } from './fragment';

export const MY_INFORMATION = gql`
  query MyInformation {
    myInformation {
      ...InformationInfo
    }
  }
  ${INFORMATION_FRAGMENT}
`;
