import gql from 'graphql-tag';
import { RIGHT_FRAGMENT, ROLE_FRAGMENT } from '../role/fragment';

export const MEMBER_FRAGMENT = gql`
  fragment MemberInfo on Member {
    emailAddress
    firstName
    gender
    id
    isDirector
    isActive
    team
    isOwner
    lastName
    phone
    isInvitationAccepted
    rights {
      ...RightInfo
    }
    role {
      ...RoleInfo
    }
  }
  ${ROLE_FRAGMENT}
  ${RIGHT_FRAGMENT}
`;

export const MEMBER_INVITED_FRAGMENT = gql`
  fragment MemberInvitedInfo on MemberInvited {
    city
    corporateLegalName
    deleted
    firstName
    gender
    id
    isInvitationAccepted
    isShares
    isoCountryCode
    lastName
    nationalityCode
    phone
    postalCode
    role
    specifiedUSPerson
    street
  }
`;
