import React from 'react';
import TransactionSub from './TransactionSub';
import { useAppSelector } from '../../../redux/hook';
import LedgerSub from './LedgerSub';
import CardSub from './CardSub';

const GlobalSubListener = () => {
  const { myLedger } = useAppSelector((state) => state.ledger);
  const { corporate } = useAppSelector(
    (state) => state.information.myInformation,
  );
  return (
    <>
      <TransactionSub ledgerLinkcyId={myLedger?.linkcyId ?? ''} />
      <LedgerSub corporateLinkcyId={corporate?.linkcyId ?? ''} />
      <CardSub ledgerLinkcyId={myLedger?.linkcyId ?? ''} />
    </>
  );
};

export default GlobalSubListener;
