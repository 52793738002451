import gql from 'graphql-tag';

export const TOKEN_EXPIRATION = gql`
  query TokenExpiration {
    tokenExpiration
  }
`;

export const CHECK_LOGIN_ONBOARDING = gql`
  query CheckLoginFromOnboarding($token: String!) {
    checkLoginFromOnboarding(token: $token)
  }
`;
