import { makeStyles } from '@material-ui/core';
import { mode } from '../../../../App';
import { colorDark, colorLight } from '../../../../theme';
import { VIEW } from '../../../../utils/constant';

export default makeStyles((theme) => ({
  appBar: {
    height: 90,
    paddingLeft: 104,
    background: 'none',
    boxShadow: 'none !important',
    zIndex: 5,
    justifyContent: 'center',
    '& p': {
      color: theme.palette.text.primary,
    },
    '& h6': {
      color: mode === VIEW.LIGHT ? theme.palette.text.primary : '#888DA8',
      fontWeight: 600,
    },
  },
  fullWidth: {
    paddingLeft: 250,
  },
  toolBar: {
    backgroundColor:
      theme.palette.type === VIEW.LIGHT
        ? colorLight.appBackground
        : colorDark.appBackground,
    height: '100%',
  },
  ktoolbar: {
    height: '100%',
  },
  headerSetting: {
    backgroundColor: mode === VIEW.LIGHT ? '#fafafa' : '#262A3',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    marginLeft: 'auto',
    position: 'relative',
  },
  companyName: {
    marginTop: theme.spacing(-2),
    color: theme.palette.text.primary,
    '& span:last-of-type': {
      fontSize: 24,
      fontWeight: 550,
    },
  },
}));
