export default {
  CORPORATE_KYB_MUST_BE_VALIDATED:
    "Your account must be validated to create the beneficiary.",
  LINKCY_STATUS_MUST_BE_VALIDATED:
    "Your account must be validated to create the beneficiary.",
  END_USER_MUST_NOT_BE_SUSPENDED:
    "Your account has been suspended. You cannot create the beneficiary.",
  BANKING_STATUS_MUST_BE_VALIDATED:
    "Your account's banking status must be validated to create the beneficiary.",
  BENEFICIARY_ALREADY_EXISTS: "The beneficiary already exists.",
};
