import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ListCard_listCard } from '../../graphql/graphql_card/card/types/ListCard';
import { ListCardType_listCardType } from '../../graphql/graphql_card/card/types/ListCardType';
import { AllCard_allCard_content } from '../../graphql/graphql_card_linkcy/card/types/AllCard';

export interface ISetCard {
  cardLimitData?: string;
  companyData?: string;
  myOfferData?: string;
  dataCardList?: string;
  roleRights?: string;
}
export const cardSlice = createSlice({
  name: 'card',
  initialState: {
    header: {
      cardLimitData: '',
      companyData: '',
      myOfferData: '',
      dataCardList: '',
      roleRights: '',
    },
    membersDataCards: '',
    CategoriesCards: '',
    canCreateCard: false,
    listCard: [] as (ListCard_listCard | null)[],
    allCard: [] as (AllCard_allCard_content | null)[],
    listCardType: [] as (ListCardType_listCardType | null)[],
  },
  reducers: {
    setCanCreateCard: (state, action: PayloadAction<boolean>) => {
      state.canCreateCard = action.payload;
    },
    setCard: (state, action: PayloadAction<ISetCard>) => {
      let newObject = {};
      Object.assign(newObject, action.payload);
      state.header = { ...state.header, ...newObject };
    },
    setMembersCards: (state, action: PayloadAction<string>) => {
      state.membersDataCards = action.payload;
    },
    setCategoriesCards: (state, action: PayloadAction<string>) => {
      state.CategoriesCards = action.payload;
    },
    setListCard: (
      state,
      action: PayloadAction<(ListCard_listCard | null)[]>,
    ) => {
      state.listCard = action.payload;
    },
    setAllCard: (
      state,
      action: PayloadAction<(AllCard_allCard_content | null)[]>,
    ) => {
      state.allCard = action.payload;
    },
    setListCardType: (
      state,
      action: PayloadAction<(ListCardType_listCardType | null)[]>,
    ) => {
      state.listCardType = action.payload;
    },
  },
});

export const {
  setCard,
  setMembersCards,
  setCategoriesCards,
  setCanCreateCard,
  setListCard,
  setListCardType,
  setAllCard,
} = cardSlice.actions;
export default cardSlice.reducer;
