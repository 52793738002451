export const removeObject = (list: any, obj: any) => {
  let tab: any[] = [];
  if (list?.length && obj.id) {
    tab = [...list];
    const index = tab.findIndex((t) => t?.id == obj.id);
    if (index != -1) {
      tab.splice(index, 1);
    }
  }
  return tab;
};

export const addOrUpdateObject = (list: any, obj: any) => {
  let tab: any[] = [];
  if (list?.length) {
    tab = [...list];
    if (obj?.id) {
      const index = tab.findIndex((t) => t?.id == obj.id);
      if (index == -1) {
        //Add
        tab = [obj, ...tab];
      } else {
        //Update
        tab[index] = obj;
      }
    }
  } else {
    //Add
    tab = [obj, ...tab];
  }
  return tab;
};

export const removeAllSpace = (str: string) => {
  return str.replace(/\s+/g, '').trim();
};

export const cleanedSpace = (str: string) => {
  return str.replace(/\s+/g, ' ').trim();
};

export const transformNameOnCard = (_name: string) => {
  if (!_name) return;

  const name = cleanedSpace(_name);
  // Convert the input name to uppercase
  let transformedName = name.toUpperCase();

  // Remove any invalid characters (anything that is not an uppercase letter or space)
  transformedName = transformedName.replace(/[^A-Z\s]/g, '');

  // Ensure the length is between 1 and 21 characters
  if (transformedName.length > 21) {
    transformedName = transformedName.substring(0, 21);
  }
  return transformedName;
};
