import { Box } from "@material-ui/core";
import React, { FC } from "react";
import styles from "./styles";
import {
  LINK_APPLE_STORE_LINKCY_AUTENTICATOR,
  LINK_PLAY_STORE_LINKCY_AUTENTICATOR,
} from "../../../config";
interface LinkStoreProps {
  width: number;
}

const LinkStore: FC<LinkStoreProps> = (props: LinkStoreProps) => {
  const classes = styles();
  const { width } = props;

  const onCLick = (link: string) => {
    window.open(link, "_blank");
  };

  return (
    <Box className={classes.store}>
      <img
        className={classes.imageStore}
        src={`/assets/linkcy/Apple-Store.png`}
        alt="apple-store"
        width={width}
        onClick={() => {
          onCLick(LINK_APPLE_STORE_LINKCY_AUTENTICATOR);
        }}
      />
      <img
        className={classes.imageStore}
        src={`/assets/linkcy/Play-Store.png`}
        alt="play-strore"
        width={width + 40}
        onClick={() => {
          onCLick(LINK_PLAY_STORE_LINKCY_AUTENTICATOR);
        }}
      />
    </Box>
  );
};

export default LinkStore;
