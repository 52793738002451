export default {
  'transaction.creancy.update.success': 'Successful update of a creditor',
  'transaction.creancy.add.success': 'Creditor add with success',
  'transaction.creancy.delete.success':
    'Delete creditor  successfully completed',
  'transaction.beneficiary.delete.success':
    'Delete beneficiary  successfully completed',
  'transaction.delete.title': 'Delete',
  'transaction.creancy.message.delete':
    'Do you really want to delete this creditor?',
  'dialog.createTransaction.title': 'Choose the type of transaction:',
  'dialog.createVirement': 'Create transfer',
  'dialog.createPrelevement': 'Create picking',
  'dialog.create.virement.beneficiary': 'Beneficiary',
  'dialog.create.virement.beneficiary.name': 'Name',
  'dialog.create.virement.beneficiary.bic': 'BIC',
  'dialog.create.virement.beneficiary.iban': 'IBAN',
  'dialog.create.virement.fast.payment': 'Fast Payment',
  'dialog.create.virement.beneficiary.type': 'Beneficiary Type',
  'dialog.create.virement.details': 'Title',
  'dialog.create.virement.title': 'Title',
  'dialog.create.virement.details.amount': 'Amount',
  'dialog.create.virement.details.category': 'Category',
  'dialog.create.virement.details.recurrence': 'Recurrence',
  'dialog.create.virement.details.file': 'Supporting document',
  'dialog.create.virement.details.textarea': 'Note',
  'dialog.create.virement.details.button.addBeneficiary':
    'Add multiple beneficiaries (CSV)',
  'dialog.create.prelevement.creditor': 'Creditor',
  'dialog.create.prelevement.name': 'Name',
  'dialog.create.prelevement.ics': 'Creditor Identifier',
  'dialog.create.prelevement.reference': 'Unique Mandate Reference',
  'dialog.create.prelevement.ref': 'Ref',
  'creancy.ics.empty': 'Please insert the creditor identifier',
  'creancy.name.empty': 'Please insert the name of the creditor',
  'creancy.ref.empty': 'Please insert the unique mandate reference',
  'dialog.create.prelevement.search': 'Search for a creditor',
  'dialog.button.cancel': 'Cancel',
  'dialog.button.create': 'Create',
  'dialog.create.virement.details.endTransaction': 'End transaction',
  'dialog.create.virement.details.dateTransaction': 'Date transaction',
  'dialog.create.virement.search': 'Search beneficiary',
  'transaction.name.empty': 'Name of beneficiary is required',
  'transaction.title.empty': 'The transfer title is required',
  'transaction.ref.empty': 'Reference is required',
  'transaction.amount.empty': 'The amount of transaction is required',
  'transaction.categoryId.empty': 'Please set the category of transaction',
  'transaction.recurrenceDate.notValid': 'End date transaction is invalid',
  'transaction.amount.moreThan.authoriezBalance':
    'the amount is more than authorized balance',

  'transaction.beneficiary.external':
    'Cannot create a bank transfer for an external beneficiary',
  'transaction.beneficiary.message.delete':
    'Do you really want to delete this beneficiary?',

  'mail.confirmation': 'A code has been sent in your email box.',
  'code.confirmed': 'Your code is correct',
  'code.confirmed.by.mobile': 'Your transaction is validated by mobile.',
  'code.confirmed.by.mobile.error': 'Mobile transaction validation failed',
  'code.error': 'Your code is maybe wrong or expired',
  'transaction.amount.min':
    'The amount of the transaction must be more than 1 Euro.',
};
