import { useApolloClient, useQuery } from '@apollo/client';
import { green, red } from '@material-ui/core/colors';
import { Snackbar } from '@material-ui/core/';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import { Theme } from '@material-ui/core/styles';
import withStyles from '@material-ui/styles/withStyles';
import React from 'react';
import { GET_SNACKBAR_STATE } from '../../../graphql/Common/snackbar/query';
import { MESSAGE_TYPE } from '../../../utils/constant';
import { ACCESS_TOKEN_KEY } from '../../../services/LocalStorage';

const styles = (theme: Theme) => ({
  snackSuccess: {
    backgroundColor: green[600],
    fontSize: 16,
  },
  snackError: {
    backgroundColor: red[600],
    fontSize: 16,
  },
  snackTransparent: {
    backgroundColor: 'transparent',
    fontSize: 16,
  },
});

const GlobalSnackBar = (props: any) => {
  const { classes } = props;
  const client = useApolloClient();
  const init = {
    isOpen: false,
    message: '',
    type: MESSAGE_TYPE.INFO,
    isLogin: true,
  };
  const token = localStorage.getItem(ACCESS_TOKEN_KEY);

  const closeSnack = () => {
    client.writeQuery({
      query: GET_SNACKBAR_STATE,
      data: {
        snackBar: { __typename: 'SnackBar', ...init },
      },
    });
  };

  const { data } = useQuery(GET_SNACKBAR_STATE, {
    fetchPolicy: 'network-only',
  });

  if (!data) {
    return null;
  }
  const { isOpen, message, type, isLogin } = data?.snackBar
    ? data.snackBar
    : init;
  return (
    <>
      {(isLogin || token) && (
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={isOpen}
          autoHideDuration={5000}
          onClose={closeSnack}
        >
          <SnackbarContent
            onClick={closeSnack}
            className={
              type === MESSAGE_TYPE.SUCCESS
                ? classes.snackSuccess
                : type === MESSAGE_TYPE.ERROR
                ? classes.snackError
                : classes.snackTransparent
            }
            message={message}
          />
        </Snackbar>
      )}
    </>
  );
};

export default withStyles(styles)(GlobalSnackBar);
