import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  box: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    fontSize: 20,
    margin: "32px auto",
  },
  text: {
    color: "#3D97F7",
    fontWeight: "bold",
    whiteSpace: "nowrap",
  },
  textContent: {
    textAlign: "center",
    fontSize: 18,
  },
  code: {
    textAlign: "center",
    fontSize: 20,
    fontWeight: "bold",
    letterSpacing: 3,
    marginTop: 10,
    marginBottom: 10,
  },
  authContent: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  textInstruction: {
    fontSize: 15,
    textAlign: "center",
    padding: 10,
  },
  textInstructionColor: {
    color: "#3D97F7",
    fontWeight: "bold",
    whiteSpace: "nowrap",
  },
  image: {
    paddingLeft: "25%",
    display: "flex",
  },
  slickSlide: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  button: {
    width: "20%",
    height: 46,
    fontSize: "1rem",
    fontWeight: 700,
    textTransform: "uppercase",
    marginTop: theme.spacing(1),
  },
  modalContent: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: 0,
    margin: 0,
  },
  titleButton: {
    fontWeight: 500,
    margin: 10,
    padding: 10,
  },
  deleteDialog: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  store: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
  },
  imageStore: {
    cursor: "pointer",
    marginLeft: 10,
    marginRight: 10,
  },
}));
