import { Box, Typography, useMediaQuery } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import classname from 'classnames';
import React, { FC, useState } from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { useTheme } from '../../../../App';
import { arrondi } from '../../../../utils/common';
import { VIEW } from '../../../../utils/constant';
import Notification from '../../../Notification';
import AppBarProfile from '../../AppBarProfile';
import MemberName from '../../MemberName';
import styles from './style';
import SwitchMode from './SwitchMode/SwitchMode';
import { useLocation } from 'react-router-dom';
import { getThemeTypeStorage } from '../../../../services/LocalStorage';
import ShowTransaction from '../../ShowTransaction/ShowTransaction';
import { setPriceLocale } from '../../../../services/Locale';
import { useAppSelector } from '../../../../redux/hook';

const Header: FC = () => {
  let viewHeader = false;
  const classes = styles({});
  const { availableAmount } = useAppSelector((state) => state.ledger.myLedger);
  const themeStorage = getThemeTypeStorage() === VIEW.LIGHT ? false : true;
  const [themeCheked, setThemeChecked] = useState(themeStorage);
  const themeToggle = useTheme();
  const isFullWidth = useMediaQuery('(min-width:1601px)');
  const history = useLocation();
  const isSetting = history.pathname === '/setting';
  const isService =
    history.pathname === '/service' || history.pathname === '/cheque';

  const handleChange = () => {
    setThemeChecked((prev) => !prev);
    themeToggle.toggle();
  };

  if (isSetting) {
    viewHeader = true;
  }
  return (
    <Box>
      {!isService && (
        <AppBar
          className={classname(
            classes.appBar,
            isFullWidth ? classes.fullWidth : {},
          )}
          position="fixed"
          color="default"
        >
          <Toolbar className={!viewHeader ? classes.toolBar : classes.ktoolbar}>
            {!viewHeader && (
              <Box display="flex" alignItems="center" flexGrow={1}>
                <Box marginRight={3}>
                  <Box display="flex" justifyContent="flex-end">
                    <Typography variant="h2" className={classes.companyName}>
                      <FormattedMessage id="greeting" />
                      <MemberName />
                    </Typography>
                  </Box>
                  <Typography>
                    <FormattedDate
                      value={new Date()}
                      year="numeric"
                      month="long"
                      day="2-digit"
                    />
                  </Typography>
                </Box>
                <Box flexBasis="83%" display="flex" textAlign="center">
                  <ShowTransaction
                    type={'Solde'}
                    price={
                      availableAmount != null &&
                      availableAmount != undefined &&
                      !isNaN(availableAmount)
                        ? setPriceLocale(arrondi(availableAmount))
                        : '-'
                    }
                    color="#329AEC"
                  />
                  <ShowTransaction
                    type={'Entrant'}
                    price={'-'}
                    color="#4CAF50"
                  />
                  <ShowTransaction
                    type={'Sortant'}
                    price={'-'}
                    color="#FB4766"
                  />
                </Box>
              </Box>
            )}
            <Box className={classes.headerSetting}>
              <SwitchMode checked={themeCheked} handleChange={handleChange} />
              <Notification />
              <AppBarProfile />
            </Box>
          </Toolbar>
        </AppBar>
      )}
    </Box>
  );
};
export default Header;
