import {
  clearLocalStorage,
  getAccessToken,
} from '../../../services/LocalStorage';
import { useAppDispatch } from '../../../redux/hook';
import { useNavigate } from 'react-router-dom';
import { clearToken } from '../../../redux/auth/loginSlice';
import { _dashboards } from '../../../indexDB/data';
import {
  Logout,
  LogoutVariables,
} from '../../../graphql/graphql_account/signin/types/Logout';
import { LOGOUT } from '../../../graphql/graphql_account/signin/mutation';
import { useMutation } from '@apollo/client';
import { informationClient } from '../../../apolloClient';
import { removeDB } from '../../../indexDB/utils';

export const useLogout = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const token = getAccessToken() || '';

  const [logoutApi] = useMutation<Logout, LogoutVariables>(LOGOUT, {
    client: informationClient,
  });

  const logout = async () => {
    logoutApi({ variables: { token } });
    await removeDB();
    dispatch(clearToken());
    clearLocalStorage();
    navigate('/login');
  };

  return { logout };
};
