import {
  TRANSACTION_STATUS,
  TRANSACTION_TYPE,
} from '../../../enums/transaction';
import { AllTransaction_allTransaction_content } from '../../../graphql/graphql_transaction_linkcy/transaction/types/AllTransaction';
import { _transactions } from '../../../indexDB/data';
import { useAppDispatch, useAppSelector } from '../../../redux/hook';
import {
  setLastCardTransaction,
  setLastTransaction,
  setTransactions,
  setTxInvalidated,
} from '../../../redux/transaction/transactionSlice';
import { addOrUpdateObject, removeObject } from '../../../utils/utils';

export const useCreateOrUpdateTransactionCache = () => {
  const dispatch = useAppDispatch();
  const { txInvalidated, transactions, lastTransaction, lastCardTransaction } =
    useAppSelector((state) => state.transaction);

  const updateOrCreate = (
    transaction: AllTransaction_allTransaction_content,
  ) => {
    // update list transaction invalidated
    const { status, type } = transaction;
    if (
      status == TRANSACTION_STATUS.APPROVED ||
      status == TRANSACTION_STATUS.ABORTED ||
      status == TRANSACTION_STATUS.DECLINED ||
      status == TRANSACTION_STATUS.RELEASED ||
      status == TRANSACTION_STATUS.REVERSED ||
      status == TRANSACTION_STATUS.UNKNOWN
    ) {
      removeInvalid(transaction.id);
      // update list transaction validated
      const listValid = addOrUpdateObject(transactions, transaction);
      dispatch(setTransactions([...listValid]));

      if (
        type == TRANSACTION_TYPE.ACQUIRING ||
        type == TRANSACTION_TYPE.CARD_AUTHORIZATION ||
        type == TRANSACTION_TYPE.CARD_CLEARING
      ) {
        // update list last transaction validated
        const listLastCardTx = addOrUpdateObject(
          lastCardTransaction,
          transaction,
        );
        dispatch(setLastCardTransaction([...listLastCardTx]));
      } else {
        // update list last transaction validated
        const listLastTx = addOrUpdateObject(lastTransaction, transaction);
        dispatch(setLastTransaction([...listLastTx]));
      }
    } else if (
      status == TRANSACTION_STATUS.PENDING ||
      status == TRANSACTION_STATUS.PENDING_REVIEW
    ) {
      const listInvalid = addOrUpdateObject(txInvalidated, transaction);
      dispatch(setTxInvalidated([...listInvalid]));
    }
  };

  const removeInvalid = (id: string | null) => {
    if (id) {
      const listInvalid = removeObject(txInvalidated, { id });
      dispatch(setTxInvalidated([...listInvalid]));
    }
  };

  return { updateOrCreate, removeInvalid };
};
