import {
  StringMapInterface,
  PeriodInterface,
  NumberMapInterface,
  NumberKeyMapInterface,
  IItems,
} from '../interface/CommonInteface';

export const START_OFFER_PRICE = '23.99';
export const DEFAULT_OFFER_NAME = 'Smart';
export const SAPHEER_COLOR = '#329AEC';

export enum TreezorDocType {
  ADDRESS_PROOF_DOC = 'ADDRESS_PROOF_DOC',
  VIGILANCE_SUP_DOC = 'VIGILANCE_SUP_DOC',
  COMPANY_REGISTRATION = 'COMPANY_REGISTRATION',
  IDENTITY_DOC = 'IDENTITY_DOC',
  HOSTING_PROOF_IDENTITY = 'HOSTING_PROOF_IDENTITY',
  HOSTING_PROOF_ADDRESS = 'HOSTING_PROOF_ADDRESS',
  HOSTING_PROOF_ATTESTATION = 'HOSTING_PROOF_ATTESTATION',
}

export enum DeviceType {
  WEB = 'WEB',
  MOBILE = 'MOBILE',
}

export enum CIN_ENUM {
  RECTO = 'RECTO',
  VERSO = 'VERSO',
}

export enum PersonType {
  LEADER_DIRECTOR = 1,
  SHAREHOLDER = 2,
  LEADER_MENDATOR = 3,
  EMPLOYE = 4,
}

export enum INST_BOLT {
  bolt = '⚡',
}

export enum AppFeatures {
  UNIVERS_TRANSACTION = 'UNIVERS_TRANSACTION',
  UNIVERS_CARD = 'UNIVERS_CARD',
  UNIVERS_MEMBERS = 'UNIVERS_MEMBERS',
  UNIVERS_DASHBOARD = 'UNIVERS_DASHBOARD',
}

export const GENDER_ITEMS = [
  { id: 'M', label: 'Mr' },
  { id: 'MME', label: 'Mme' },
];

export enum OfferNames {
  START = 'Start',
  SMART = 'Smart',
  PREMIUM = 'Premium',
  CORPORATE = 'Corporate',
}
export enum MESSAGE_TYPE {
  ERROR = 'ERROR',
  SUCCESS = 'SUCCESS',
  WARNING = 'WARNING',
  INFO = 'INFORMATION',
}

export enum CardState {
  UNLOCK = 0,
  LOCK = 1,
  LOST = 2,
  STOLEN = 3,
  DESTROYED = 4,
}

export enum HIPAY_ORDER {
  TOPUP = 'TOPUP',
  FACTURE = 'FACTURE',
  COMMAND_CARD = 'COMMAND_CARD',
}

export enum VIEW {
  LIGHT = 'light',
  DARK = 'dark',
}
export enum TR_VIEW {
  RECTO = 'RECTO',
  VERSO = 'VERSO',
}
export enum MEMBER_VIEW_STYLE {
  CARD = 'CARD',
  TABLE = 'TABLE',
}

export enum CARD_PAYMENT_TYPE {
  MONTHLY = 'MONTHLY',
  YEARLY = 'YEARLY',
}

export enum TOOLBAR_TEAM_FILTER {
  NONE = 'NONE',
  NAME = 'NAME',
  FIRSTNAME = 'FIRSTNAME',
  ROLE = 'ROLE',
  TEAM = 'TEAM',
}

export enum TOOLBAR_CARD_FILTER {
  NONE = 'NONE',
  NAME = 'NAME',
  FIRSTNAME = 'FIRSTNAME',
  VIRTUAL = 'VIRTUAL',
  PHYSICAL = 'PHYSICAL',
  ACTIVATE = 'ACTIVATE',
}

export enum ROLE_TYPE {
  ADMIN = 'Admin',
  CONTRIBUTOR = 'Collaborateur',
  ACCOUNTANT = 'Comptable',
}

export const ROLE_DESCRIPTION: StringMapInterface = {
  Admin: 'teamMemberDialog.label.adminDescription',
  Collaborateur: 'teamMemberDialog.label.collaboratorDescription',
  Comptable: 'teamMemberDialog.label.accountantDescription',
};

export const ROLE_LABEL: StringMapInterface = {
  Admin: 'label.admin',
  Collaborateur: 'label.contributor',
  Comptable: 'label.accountant',
};

export const TVA: IItems[] = [
  {
    id: '0',
    label: '0.90%',
  },
  {
    id: '1',
    label: '1.05%',
  },
  {
    id: '2',
    label: '1.75%',
  },
  {
    id: '3',
    label: '2.1%',
  },
  {
    id: '4',
    label: '8.5%',
  },
  {
    id: '5',
    label: '5.5%',
  },
  {
    id: '6',
    label: '10%',
  },
  {
    id: '7',
    label: '20%',
  },
];

export const TEAM_LABEL: StringMapInterface = {
  Direction: 'label.directionTeam',
  Finance: 'label.finance',
  Marketing: 'label.marketing',
  Informatique: 'label.informatique',
};

export enum CONSTANT_CODE {
  HAVE_OWN_CARDS = 'HAVE_OWN_CARDS',
  VALIDATE_REQUEST = 'VALIDATE_REQUEST',
  EDIT_TRANSACTION_DETAILS = 'EDIT_TRANSACTION_DETAILS',
  VIEW_HIS_TRANSACTION = 'VIEW_HIS_TRANSACTION',
  VIEW_EXPORT_ALL_TRANSACTIONS = 'VIEW_EXPORT_ALL_TRANSACTIONS',
  VIEW_STC = 'VIEW_STC',
  INIT_TRANSFER = 'INIT_TRANSFER',
  MANAGE_ALL_CARDS = 'MANAGE_ALL_CARDS',
  MANAGE_TEAM = 'MANAGE_TEAM',
  EXECUTE_REQUEST = 'EXECUTE_REQUEST',
}

export enum MEMBER_SELECT {
  ROLE = 'role',
  TEAM = 'team',
}

export enum CARD_TYPE {
  PHYSICAL = 'physical',
  VIRTUAL = 'virtual',
}

export enum CARD_LIMIT_EXCEEDED {
  PHYSICAL = 'PHYSICAL_CARD_LIMIT_EXCEEDED',
  VIRTUAL = 'VIRTUAL_CARD_LIMIT_EXCEEDED',
}

export enum OFFERS_NAME {
  START = 'Start',
  SMART = 'Smart',
  PREMIUM = 'Premium',
  CORPORATE = 'Corporate',
}

export enum OFFERS_CARD_PRICE {
  START = '23.99',
  SMART = '11.99',
  PREMIUM = '23.99',
  CORPORATE = '239.99',
}

export enum OFFERS_NAME_LIMIT {
  START = 0,
  START_V = 1,
  SMART = 1,
  PREMIUM = 2,
  CORPORATE = 20,
}

export const CUSTOM_SYMBOL = '+';

export const PERIOD_FILTERS: PeriodInterface[] = [
  { label: 'LAST_3_DAYS', value: '3' },
  { label: 'LAST_7_DAYS', value: '7' },
  { label: 'LAST_10_DAYS', value: '10' },
  { label: 'LAST_15_DAYS', value: '15' },
  { label: 'LAST_MONTH', value: '30' },
  { label: 'LAST_2_MONTHS', value: '60' },
  { label: 'LAST_3_MONTHS', value: '90' },
];

export enum STATISTIC_TYPE_FILTER {
  MEMBER = '0',
  CATEGORY = '1',
  FLUX = '2',
}

export const PERIOD_VALUES: NumberMapInterface = {
  LAST_3_DAYS: 3,
  LAST_7_DAYS: 7,
  LAST_10_DAYS: 10,
  LAST_15_DAYS: 15,
  LAST_MONTH: 30,
  LAST_2_MONTHS: 60,
  LAST_3_MONTHS: 90,
};

export enum PERIOD_ENUM {
  LAST_3_DAYS = 'LAST_3_DAYS',
  LAST_7_DAYS = 'LAST_7_DAYS',
  LAST_10_DAYS = 'LAST_10_DAYS',
  LAST_15_DAYS = 'LAST_15_DAYS',
  LAST_MONTH = 'LAST_MONTH',
  LAST_2_MONTHS = 'LAST_2_MONTHS',
  LAST_3_MONTHS = 'LAST_3_MONTHS',
}

export const PERIOD_VALUES_NUMBER: NumberKeyMapInterface = {
  3: 'LAST_3_DAYS',
  7: 'LAST_7_DAYS',
  10: 'LAST_10_DAYS',
  15: 'LAST_15_DAYS',
  30: 'LAST_MONTH',
  60: 'LAST_2_MONTHS',
  90: 'LAST_3_MONTHS',
};

export enum CURRENCY {
  EUR = 'EUR',
}

export enum CARD_PRICE {
  MONTHLY = '1.99',
  PAY_ONCE = '29.99',
}
export enum SORT_TYPE {
  ASC = 'ASC',
  DESC = 'DESC',
}

export enum STAT_DETAILS_TYPE {
  MEMBERS = 'MEMBERS',
  CATEGORIES = 'CATEGORIES',
  FLUX = 'FLUX',
}

export const CURRENCY_SYMBOL: StringMapInterface = {
  EUR: '€',
  USD: '$',
  GBP: 'GBP',
};

export const CURRENCY_VALUE: StringMapInterface = {
  '€': 'EUR',
  $: 'USD',
  GBP: 'GBP',
};

export enum COLOR_GRAPH {
  IN = '#00A878',
  OUT = '#FB4766',
}

const PERIOD_DAYS: IItems[] = [
  {
    id: '3',
    label: 'LAST_3_DAYS',
  },
  {
    id: '7',
    label: 'LAST_7_DAYS',
  },
  {
    id: '10',
    label: 'LAST_10_DAYS',
  },
  {
    id: '15',
    label: 'LAST_15_DAYS',
  },
];

const PERIOD_MONTHS: IItems[] = [
  {
    id: '30',
    label: 'LAST_MONTH',
  },
  {
    id: '60',
    label: 'LAST_2_MONTHS',
  },
  {
    id: '90',
    label: 'LAST_3_MONTHS',
  },
];

export const PERIOD_FILTER_FOR_STAT: IItems[] = [
  ...PERIOD_DAYS,
  ...PERIOD_MONTHS,
];

export const PERIOD_FILTER_FOR_TX: IItems[] = [
  {
    id: '-1',
    label: 'femaleNone',
  },
  ...PERIOD_DAYS,
  ...PERIOD_MONTHS,
];

export const PERIOD_FILTER_STATEMENT: IItems[] = [
  {
    id: '-1',
    label: 'date',
  },
  ...PERIOD_MONTHS,
];
export enum FILE_TYPE_ENUM {
  PDF = 'PDF',
  EXCEL = 'EXCEL',
  CSV = 'CSV',
}
export const FILE_TYPE_STATEMENT: IItems[] = [
  { id: FILE_TYPE_ENUM.CSV, label: 'Csv' },
  { id: FILE_TYPE_ENUM.EXCEL, label: 'Excel' },
  { id: FILE_TYPE_ENUM.PDF, label: 'Pdf' },
];

export const MODALITY_FOR_FILTER: IItems[] = [
  { id: '-1', label: 'femaleAll' },
  { id: '6', label: 'transaction.modality.abonnement' },
  { id: '4', label: 'transaction.modality.cheque' },
  { id: '7', label: 'transaction.modality.frais' },
  { id: '9', label: 'transaction.card.payment' },
  { id: '2', label: 'transaction.modality.payout' },
  { id: '10', label: 'transaction.card.withdrawal' },
  { id: '12', label: 'transaction.card.topup' },
  { id: '0', label: 'transaction.modality.transfer' },
  { id: '1', label: 'transaction.modality.payin' },
];

export const MODALITY_FOR_FILTER_RELEVE: IItems[] = [
  { id: '-1', label: 'femaleAll' },
  { id: '6', label: 'transaction.modality.abonnement' },
  { id: '4', label: 'transaction.modality.cheque' },
  { id: '7', label: 'transaction.modality.frais' },
  { id: '9', label: 'transaction.card.payment' },
  { id: '2', label: 'transaction.modality.payout' },
  { id: '10', label: 'transaction.card.withdrawal' },
  { id: '12', label: 'transaction.card.topup' },
  { id: '0', label: 'transaction.modality.transfer' },
  { id: '1', label: 'transaction.modality.payin' },
];

export const MODALITY_FOR_STATEMENT: IItems[] = [
  ...MODALITY_FOR_FILTER,
  { id: '4', label: 'transaction.modality.check' },
];

export const RECURRENCE_FOR_FILTER: IItems[] = [
  { id: '-1', label: 'femaleNone' },
  { id: '1', label: 'transaction.recurrence.weekly' },
  { id: '2', label: 'transaction.recurrence.monthly' },
  { id: '3', label: 'transaction.recurrence.yearly' },
];

export const MODALITY_TYPE_FOR_FILTER: IItems[] = [
  { id: '-1', label: 'alls' },
  { id: '0', label: 'incoming' },
  { id: '1', label: 'outgoing' },
];

export enum NOTIFICATION_TYPE {
  MESSAGE = 'MESSAGE',
  SERVICE = 'SERVICE',
  FONCTIONNALITE = 'FONCTIONNALITE',
}

export const DEFAULT_NAME = 'Sapheer B';

export const DEFAULT_ROWS_PER_PAGE = 10;
export const DEFAULT_TR_INTO_GRID = 25;
export const DEFAULT_NB_DAYS = 10;

export enum FILE_TYPE {
  pdf = 'application/pdf',
  csv = 'text/plain',
  xlsx = 'application/octet-stream',
}

export enum DATE_TIME_FORMAT {
  DATE_ONLY = 'YYYY-MM-DD',
  DATE_FR = 'DD-MM-YYYY',
  DATE_MONTH_FR = 'MMMM YYYY',
  DATE_FRENCH = 'DD-MMMM-YYYY',
}

export const START_YEAR = '2020';

export enum SERVICE_PLUS_ENUM {
  TRESORIA = 'Tresoria',
  OTHERWISE = 'Otherwise',
  GCOLLECT = 'Gcollect',
  LAPERMANENCE = 'LaPermanence',
}

export enum KYCReview {
  NONE = 0,
  PENDING = 1,
  VALIDATED = 2,
  REFUSED = 3,
}

export const INDIVIDUAL_LEGALFORM_VALUE = '1000';

export enum LANGUAGE {
  EN = 'en_US',
  FR = 'fr_FR',
}
