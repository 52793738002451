export default {
  CORPORATE_KYB_MUST_BE_VALIDATED:
    "Votre compte doit être validé pour pouvoir effectuer un virement.",
  LINKCY_STATUS_MUST_BE_VALIDATED:
    "Votre compte doit être validé pour pouvoir effectuer un virement.",
  END_USER_MUST_NOT_BE_SUSPENDED:
    "Votre compte a été suspendu. Vous ne pouvez pas effectuer cette opération.",
  BANKING_STATUS_MUST_BE_VALIDATED:
    "Le statut bancaire de votre compte doit être validé pour pouvoir effectuer un virement.",
  LEDGER_MUST_BE_ACTIVE:
    "Votre compte a été suspendu. Vous ne pouvez pas effectuer cette opération.",
  LEDGER_MUST_BE_PROVISIONED:
    "Votre solde est insuffisant pour effectuer ce virement.",
  BENEFICIARY_MUST_BE_ACTIVE:
    "Le bénéficiaire doit être actif pour effectuer ce virement..",
  BENEFICIARY_MUST_BE_VALID:
    "Le bénéficiaire doit être valide pour effectuer ce virement.",
  LEDGER_MUST_BE_UPGRADED:
    "Votre compte doit être mis à niveau pour pouvoir effectuer un virement.",
  TERMS_AND_CONDITIONS_NOT_ACCEPTED:
    "Les termes et conditions doivent être acceptés pour pouvoir effectuer un virement.",
  BENEFICIARY_NOT_COMPATIBLE:
    "Le bénéficiaire sélectionné n'est pas compatible avec votre compte.",
  LEDGER_TRANSACTION_LIMIT_REACHED:
    "Le plafond de transaction de votre compte a été atteint.",
  LEDGER_TRANSACTION_DAILY_LIMIT_REACHED:
    "Le plafond de transactions quotidiennes de votre compte a été atteint.",
  LEDGER_TRANSACTION_MONTHLY_LIMIT_REACHED:
    "Le plafond de transactions mensuelles de votre compte a été atteint.",
};
