export default {
  CORPORATE_KYB_MUST_BE_VALIDATED:
    "Votre compte doit être validé pour créer le bénéficiaire.",
  LINKCY_STATUS_MUST_BE_VALIDATED:
    "Votre compte doit être validé pour créer le bénéficiaire.",
  END_USER_MUST_NOT_BE_SUSPENDED:
    "Votre compte a été suspendu. Vous ne pouvez pas créer le bénéficiaire.",
  BANKING_STATUS_MUST_BE_VALIDATED:
    "Le statut bancaire de votre compte doit être validé pour créer le bénéficiaire.",
  BENEFICIARY_ALREADY_EXISTS: "Le bénéficiaire existe déjà.",
};
