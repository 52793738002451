export enum INDEXEDDB_TABLES {
  TRANSACTION_LIST = "TransactionList",
  INFOS = "Infos",
  LOGIN = "Login",
  TRANSACTION_LIST_DETAIL = "TransactionListDetail",
  RECENT_CARD="RecentCardList",
  CATEGORIES_LIST="CategoriesList",
  ACCOUNT_FOR_STATE_LIST="accountForStateList",
  DATA_STATISTIC_LIST="DataStatisticList",
  DATA_TABLE_BAR_STATISTIC_LIST="DataTableBarStatisticList",
  DATA_ACCOUNT_STATE_LIST="DataAccountState",
  DATA_CARD="DataCard",
  MEMBERS_DATA_CARDS="MembersDataCards",
  CATEGORIES_CARD_DATA="CategoriesCardData",
  DATA_TEAM="DataTeam",
  DATA_SETTING="DataSetting",
}
