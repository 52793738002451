import gql from 'graphql-tag';

export const LEDGER_FRAGMENT = gql`
  fragment LegderInfo on Legder {
    availableAmount
    bicOrSwift
    friendlyName
    iban
    id
    linkcyId
    reservedAmount
    status
    totalAmount
  }
`;
