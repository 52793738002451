import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import MuiDialogContent from '@material-ui/core/DialogContent';
import { Theme, withStyles, createStyles } from '@material-ui/core/styles';
import React from 'react';
import CustomDialogTitle from './CustomDialogTitle';
import { IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

interface CustomDialogPropsInterface {
  dialogTitle: any;
  children: React.ReactNode;
  open: boolean;
  closable?: boolean;
  handleClose(): void;
  disableMaxWidth?: boolean;
  noTitle?: boolean;
}

const CustomDialog = (props: CustomDialogPropsInterface & DialogProps) => {
  const {
    dialogTitle,
    disableMaxWidth,
    children,
    open,
    closable,
    handleClose,
    noTitle,
  } = props;

  return (
    <Dialog
      maxWidth={disableMaxWidth ? false : 'md'}
      onClose={handleClose}
      aria-labelledby="contrat-sapheer"
      {...props}
      open={open}
    >
      {!noTitle ? (
        <CustomDialogTitle
          closable={closable || false}
          onClose={handleClose}
          dialogTitle={dialogTitle}
        />
      ) : (
        <IconButton
          aria-label="close"
          style={{ position: 'absolute', right: 1 }}
          onClick={handleClose}
          tabIndex={2}
        >
          <CloseIcon />
        </IconButton>
      )}

      <DialogContent style={{ paddingTop: noTitle ? 30 : 0 }}>
        {children}
      </DialogContent>
    </Dialog>
  );
};

export default CustomDialog;
