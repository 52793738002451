import gql from 'graphql-tag';
import { TRANSACTION_FRAGMENT } from './fragment';

export const ON_TRANSACTION_CREATE_OR_UPDATE = gql`
  subscription OnTransactionCreateOrUpdate($ledgerLinkcyId: String) {
    onTransactionCreateOrUpdate(ledgerLinkcyId: $ledgerLinkcyId) {
      ...TransactionInfo
    }
  }
  ${TRANSACTION_FRAGMENT}
`;
