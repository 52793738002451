import Box from '@material-ui/core/Box';
import React, { FC, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { mode } from '../../../App';
import { colorDark, colorLight } from '../../../theme';
import { VIEW } from '../../../utils/constant';
import { useLazyQuery } from '@apollo/client';
import {
  ACCESS_TOKEN_KEY,
  clearLocalStorage,
} from '../../../services/LocalStorage';
import UserInactivity from './UserInactivity';
import AppBar from './AppBar/AppBarProvider';
import SideBar from './Menu/MenuProvider';
import CustomProgress from '../CustomProgress/CustomProgress';
import { TokenExpiration } from '../../../graphql/graphql_information/auth/types/TokenExpiration';
import { TOKEN_EXPIRATION } from '../../../graphql/graphql_information/auth/query';
import { informationClient } from '../../../apolloClient';
import useMyInformation from '../../customHooks/information/information';
import useMyLedger from '../../customHooks/ledger/ledger';

const Layout: FC = () => {
  const { loading: loadingInfo } = useMyInformation(true);
  const { loading: loadingLedger } = useMyLedger(true);

  // check token if valid
  const [checkToken] = useLazyQuery<TokenExpiration>(TOKEN_EXPIRATION, {
    client: informationClient,
    onCompleted: (data) => {
      if (data && !data.tokenExpiration) {
        tokenNotValid();
      }
    },
    onError: () => {
      tokenNotValid();
    },
  });

  useEffect(() => {
    const token = localStorage.getItem(ACCESS_TOKEN_KEY);
    if (token) {
      checkToken({
        variables: {
          token,
        },
      });
    }
  }, []);
  // if has not connection

  const tokenNotValid = () => {
    clearLocalStorage();
    window.location.href = '/';
  };

  if (loadingLedger || loadingInfo) {
    return <CustomProgress />;
  }
  return (
    <Box>
      <UserInactivity>
        <Box display="flex" height="100vh">
          {
            <Box>
              <AppBar />
              <SideBar />
            </Box>
          }
          <Box
            flex={1}
            bgcolor={
              mode === VIEW.LIGHT
                ? colorLight.appBackground
                : colorDark.appBackground
            }
            height="100vh"
          ></Box>
        </Box>
        <Outlet />
      </UserInactivity>
    </Box>
  );
};
export default Layout;
