import gql from 'graphql-tag';

export const RIGHT_FRAGMENT = gql`
  fragment RightInfo on Right {
    id
    constantCode
    title
  }
`;

export const ROLE_FRAGMENT = gql`
  fragment RoleInfo on Role {
    id
    description
    name
    rights {
      ...RightInfo
    }
  }
  ${RIGHT_FRAGMENT}
`;

export const ROLES_RIGHTS_FRAGMENT = gql`
  fragment RolesRightsInfo on RolesRights {
    roles {
      ...RoleInfo
    }
    rights {
      ...RightInfo
    }
  }
  ${RIGHT_FRAGMENT}
  ${ROLE_FRAGMENT}
`;
