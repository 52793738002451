import gql from 'graphql-tag';
import { OFFERS_FRAGMENT } from '../offer/fragment';
import { CORPORATE_FRAGMENT } from '../corporate/fragment';
import { MEMBER_FRAGMENT } from '../member/fragment';

export const INFORMATION_FRAGMENT = gql`
  fragment InformationInfo on Information {
    offer {
      ...OffersInfo
    }
    corporate {
      ...CorporateInfo
    }
    owner {
      ...MemberInfo
    }
    members {
      ...MemberInfo
    }
    connected {
      ...MemberInfo
    }
  }
  ${OFFERS_FRAGMENT}
  ${CORPORATE_FRAGMENT}
  ${MEMBER_FRAGMENT}
`;
