import gql from 'graphql-tag';
import { LEDGER_FRAGMENT } from './fragment';

export const LEDGER_INFO = gql`
  query LedgerInfo {
    ledgerInfo {
      ...LegderInfo
    }
  }
  ${LEDGER_FRAGMENT}
`;
