import gql from 'graphql-tag';
import { CORPORATE_FRAGMENT } from '../corporate/fragment';

export const ON_VALIDATED_SCA = gql`
  subscription OnVadidatedSca($corporateLinkcyId: String) {
    onVadidatedSca(corporateLinkcyId: $corporateLinkcyId) {
      ...CorporateInfo
    }
  }
  ${CORPORATE_FRAGMENT}
`;
