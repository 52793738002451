export enum ScaDeviceStatus {
  UNSET = 'UNSET',
  PENDING = 'PENDING',
  SET = 'SET',
}

export enum AuthenticationChoise {
  BIOMETRY = 'BIOMETRY',
  PIN = 'PIN',
}

export enum AuthenticationStrategy {
  PUSH_NOTIFICATION = 'PUSH_NOTIFICATION',
  JOIN_CODE = 'JOIN_CODE',
}
