export default {
  LEDGER_NOT_FOUND:
    "The requested account could not be found. Please check the provided information.",
  CARD_PROFILE_NOT_ACTIVE:
    "The selected profile is not active. Please choose another profile.",
  CARD_PROFILE_NOT_FOUND:
    "The selected profile could not be found. Please choose another profile.",
  CARD_PROGRAM_NOT_COMPATIBLE_END_USER:
    "The selected card program is not compatible with your account.",
  DELIVERY_METHOD_REQUIRED:
    "A delivery method is required. Please select a delivery method.",
  FRIENDLY_NAME_ALREADY_TAKEN:
    "The card name you have chosen is already taken. Please select a different name.",
  CARD_RULE_NOT_UNIQUE:
    "The specified card rule is not unique. Please review and modify the rule.",
  INVALID_DELIVERY_METHOD:
    "The selected delivery method is invalid. Please choose another.",
  CARD_PROFILE_NOT_COMPATIBLE_PARTNER:
    "The selected card profile is not compatible with Sapheer.",
  CORPORATE_KYB_MUST_BE_VALIDATED:
    "Your account must be validated before you can create a card.",
  LINKCY_STATUS_MUST_BE_VALIDATED:
    "Your account must be validated to perform this operation.",
  END_USER_MUST_NOT_BE_SUSPENDED:
    "Your account has been suspended. You cannot perform this operation.",
  BANKING_STATUS_MUST_BE_VALIDATED:
    "Your banking account must be validated to perform this operation.",
  CARD_PROGRAM_NOT_COMPATIBLE_LEDGER:
    "The selected card program is not compatible with the account.",
  TERMS_AND_CONDITIONS_NOT_ACCEPTED:
    "Terms and conditions must be accepted before you can create a card.",
  LEDGER_MUST_BE_ACTIVE: "The account must be active to create a card.",
  INVALID_CARD_PROGRAM_TYPE:
    "The selected card program type is invalid. Please choose another.",
  LEDGER_MUST_NOT_BE_SUSPENDED:
    "Your account has been suspended. You cannot create a card.",
  LEDGER_MUST_NOT_BE_VIRTUAL:
    "The account must not be virtual to perform this action.",
  INVALID_CARD_PROGRAM_COUNTRY:
    "The selected card program is not available in your country. Please choose another.",
  UNSUPPORTED_DELIVERY_METHOD:
    "The selected delivery method is not supported. Please choose another.",
  RESERVED_BALANCE_MUST_BE_EMPTY_WHEN_CREATING_FIRST_CARD:
    "Another operation is currently being processed on your account. Please try again later to create your first card.",
  OTHER_CARD_BEING_CREATED_FOR_SAME_LEDGER:
    "Another card is currently being created. Please try again later.",
  CARD_MUST_BE_SUSPENDED_OR_AWAITING_ACTIVATION:
    "The card must be suspended or awaiting activation to perform this operation.",
  INSUFFICIENT_RIGHTS:
    "You do not have sufficient rights to perform this operation.",
  INVALID_PAN: "The provided PAN is invalid. Please check the information.",
  CARD_MUST_BE_ACTIVE: "The card must be active to perform this operation.",
  OTHER_STATUS_REASON_MUST_HAVE_A_DESCRIPTION:
    "A description is required for the reason of this status.",
  CARD_MUST_BE_PHYSICAL: "The card must be physical to perform this operation.",
  CARD_NOT_FOUND:
    "The requested card could not be found. Please check the provided information.",
};
