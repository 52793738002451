import React, { useEffect, useState } from "react";
import { useIdleTimer } from "react-idle-timer";
import { useIntl } from "react-intl";
import { useLogout } from "../../../Auth/Logout/Logout";
import ConfirmationDialog from "../../ConfirmationDialog/ConfirmationDialog";

interface Props {
  children: React.ReactNode;
}

const UserInactivity = (props: Props) => {
  const { children } = props;
  const intl = useIntl();
  const [confirmation, setConfirmation] = useState(false);
  const [time, setTime] = useState<any>(null);
  const confirmationTime = 1000 * 60; // 1min

  // ---- logout apres 10 minutes d'inactivité
  const inactivityTimer = 1000 * 60 * 10;
  const { logout } = useLogout();

  const handleOnIdle = (event: any) => {
    setConfirmation(true);
    setTime(
      setTimeout(() => {
        logout();
      }, confirmationTime),
    );
  };

  const handleOnActive = (event: any) => {};
  const handleClose = () => {
    clearTimeout(time);
    setConfirmation(false);
  };
  const handleAccept = () => {};
  const handleOnAction = (e: any) => {};

  useIdleTimer({
    timeout: inactivityTimer,
    onIdle: handleOnIdle,
    onActive: handleOnActive,
    onAction: handleOnAction,
    debounce: 500,
  });
  return (
    <div style={{ display: "flex" }}>
      {confirmation && (
        <ConfirmationDialog
          title="disconnect"
          isNotConfirm={true}
          message={intl.formatMessage({ id: "user.confirmation.deconnect" })}
          open={confirmation}
          handleAccept={handleAccept}
          handleClose={handleClose}
        />
      )}
      {children}
    </div>
  );
};

export default UserInactivity;
