import localforage from 'localforage';

//---------------------------------------------------------

export enum EDbTable {
  DASHBOARDS = 'DASHBOARDS_',
  TRANSACTIONS = 'TRANSACTIONS_',
  TEAMS = 'TEAMS',
  COMPANY = 'COMPANY',
  CARDS = 'CARDS',
  SETTINGS = 'SETTINGS',
  MY_COMPANY_INFO = 'MY_COMPANY_INFO',
  OFFER = 'OFFER',
  MY_INFORMATION = 'MY_INFORMATION',
  TEAM = 'TEAM',
}

//---------------------------------------------------------

export enum DashboardItem {
  MY_ROLE_RIGHTS = 'MY_ROLE_RIGHTS',
  MY_EMAIL = 'MY_EMAIL', // OK
  MY_COMPANY_ID = 'MY_COMPANY_ID', // OK
  MY_OFFER = 'MY_OFFER', // OK
  MY_ID = 'MY_ID',
  MY_BALANCE = 'MY_BALANCE', // OK
  URL_AVATAR = 'URL_AVATAR',
}

export const _dashboards = localforage.createInstance({
  name: EDbTable.DASHBOARDS,
});

//---------------------------------------------------------

export enum TransactionItem {
  LAST_TRANSACTION = 'LAST_TRANSACTION',
  LAST_CARD_TRANSACTION = 'LAST_CARD_TRANSACTION',
  TX_INVALIDATED = 'TX_INVALIDATED',
  AUTORIZED_PRE_TX = 'AUTORIZED_PRE_TX',
  CATEGORIES = 'CATEGORIES',
}
export const _transactions = localforage.createInstance({
  name: EDbTable.TRANSACTIONS,
});

//---------------------------------------------------------

export enum SettingItem {
  IBAN = 'IBAN',
  OFFERS = 'OFFERS',
  COMPANY = 'COMPANY',
  ACCOUNT = 'ACCOUNT',
  MY_OFFER = 'MY_OFFER',
}

export const _settings = localforage.createInstance({
  name: EDbTable.SETTINGS,
});

//---------------------------------------------------------

export enum CompanyItem {
  COMPANY = 'COMPANY',
  INFO = 'INFO',
  OFFER = 'OFFER',
  CARD_LIST = 'CARD_LIST',
  CARD_LIMIT = 'CARD_LIMIT',
  ROLES_RIGHTS = 'ROLES_RIGHTS',
  BENEFICIARIES = 'BENEFICIARIES',
}

export const _company = localforage.createInstance({
  name: EDbTable.COMPANY,
});

//---------------------------------------------------------

export enum TeamItem {
  LEADER = 'LEADER',
  SHAREHOLDERS = 'SHAREHOLDERS',
  ROLES = 'ROLES',
  MEMBERS = 'MEMBERS',
  TEAMS = 'TEAMS',
}

export const _teams = localforage.createInstance({
  name: EDbTable.TEAMS,
});

//---------------------------------------------------------

export enum TimeItem {
  TOKEN_TIME = 'TOKEN_TIME',
  TX_PRE_DATA = 'TX_PRE_DATA',
}
export const _time = localforage.createInstance({
  name: EDbTable.TEAMS,
});

//---------------------------------------------------------

export enum MyCompanyInfoItem {
  MY_COMPANY = 'MY_COMPANY',
  MY_COMPANY_SETTING = 'MY_COMPANY_SETTING',
}

export const _myCompanyInfo = localforage.createInstance({
  name: EDbTable.MY_COMPANY_INFO,
});

//--------------------------------------------------------

export enum OfferItem {
  MY_OFFER = 'MY_OFFER',
  LIST_OFFER = 'LIST_OFFER',
}

export const _offers = localforage.createInstance({
  name: EDbTable.OFFER,
});

//--------------------------------------------------------

export enum CardItem {
  LIST_CARD = 'LIST_CARD',
  LIST_CARD_TYPE = 'LIST_CARD_TYPE',
}

export const _cards = localforage.createInstance({
  name: EDbTable.CARDS,
});

//---------------------------------------------------------

export enum MyInformationItem {
  MY_INFORMATION = 'MY_INFORMATION',
  MY_LEDGER = 'MY_LEDGER',
}

export const _myInformation = localforage.createInstance({
  name: EDbTable.MY_INFORMATION,
});

//--------------------------------------------------------

export enum TeamsItem {
  MEMBERS = 'MEMBERS',
  ROLES = 'ROLES',
  RIGHTS = 'RIGHTS',
  CONNECTED = 'CONNECTED',
}

export const _team = localforage.createInstance({
  name: EDbTable.TEAM,
});
