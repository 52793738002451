import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { LedgerInfo_ledgerInfo } from '../../graphql/graphql_banking/legder/types/LedgerInfo';

export const ledgerSlice = createSlice({
  name: 'ledger',
  initialState: {
    myLedger: {} as LedgerInfo_ledgerInfo,
  },
  reducers: {
    setMyLedger: (state, action: PayloadAction<LedgerInfo_ledgerInfo>) => {
      state.myLedger = action.payload;
    },
  },
});

export const { setMyLedger } = ledgerSlice.actions;

export default ledgerSlice.reducer;
