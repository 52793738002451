import { useLazyQuery } from '@apollo/client';
import { useAppDispatch } from '../../../redux/hook';
import { MY_INFORMATION } from '../../../graphql/graphql_information/information/query';
import { MyInformation } from '../../../graphql/graphql_information/information/types/MyInformation';
import { informationClient } from '../../../apolloClient';
import { useEffect } from 'react';
import { _myInformation, MyInformationItem } from '../../../indexDB/data';
import { InformationInfo } from '../../../graphql/graphql_information/information/types/InformationInfo';
import { setMyInformation } from '../../../redux/information/informationSlice';

const useMyInformation = (synchronize?: boolean) => {
  const dispatch = useAppDispatch();

  const [getInformation, { loading, data }] = useLazyQuery<MyInformation>(
    MY_INFORMATION,
    {
      fetchPolicy: 'network-only',
      client: informationClient,
      async onCompleted(data) {
        if (data.myInformation) {
          await _myInformation.setItem(
            MyInformationItem.MY_INFORMATION,
            data.myInformation,
          );
          await setData(data.myInformation);
        }
      },
    },
  );

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const cache = await _myInformation.getItem(
      MyInformationItem.MY_INFORMATION,
    );
    if (cache && !synchronize) {
      setData(cache as unknown as InformationInfo);
      return;
    }
    getInformation();
  };

  const setData = async (data: InformationInfo) => {
    dispatch(setMyInformation(data));
  };

  return { getInformation, loading, data };
};

export default useMyInformation;
