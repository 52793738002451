import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { MemberInfo } from '../../graphql/graphql_information/member/types/MemberInfo';
import { RoleInfo } from '../../graphql/graphql_information/role/types/RoleInfo';
import { RightInfo } from '../../graphql/graphql_information/role/types/RightInfo';

export const memberSlice = createSlice({
  name: 'member',
  initialState: {
    members: [] as (MemberInfo | null)[],
    roles: [] as (RoleInfo | null)[],
    rights: [] as (RightInfo | null)[],
  },
  reducers: {
    setMembers: (state, action: PayloadAction<(MemberInfo | null)[]>) => {
      state.members = action.payload;
    },
    setRoles: (state, action: PayloadAction<(RoleInfo | null)[]>) => {
      state.roles = action.payload;
    },
    setRights: (state, action: PayloadAction<(RightInfo | null)[]>) => {
      state.rights = action.payload;
    },
  },
});

export const { setMembers, setRoles, setRights } = memberSlice.actions;

export default memberSlice.reducer;
