import { indexedDBClearData } from '.';
import {
  _cards,
  _company,
  _dashboards,
  _myCompanyInfo,
  _myInformation,
  _offers,
  _settings,
  _team,
  _teams,
  _time,
  _transactions,
} from './data';
import { INDEXEDDB_TABLES } from './table';

export const clearLocalforage = async () => {
  await _dashboards.clear();
  await _company.clear();
  await _teams.clear();
  await _settings.clear();
  await _cards.clear();
  await _transactions.clear();
  await _time.clear();
  await _myCompanyInfo.clear();
  await _offers.clear();
  await _myInformation.clear();
  await _team.clear();
};

export const clearAllDataIndexeddb = async () => {
  await indexedDBClearData(INDEXEDDB_TABLES.RECENT_CARD);
  await indexedDBClearData(INDEXEDDB_TABLES.ACCOUNT_FOR_STATE_LIST);
  await indexedDBClearData(INDEXEDDB_TABLES.CATEGORIES_CARD_DATA);
  await indexedDBClearData(INDEXEDDB_TABLES.CATEGORIES_LIST);
  await indexedDBClearData(INDEXEDDB_TABLES.DATA_ACCOUNT_STATE_LIST);
  await indexedDBClearData(INDEXEDDB_TABLES.DATA_CARD);
  await indexedDBClearData(INDEXEDDB_TABLES.DATA_SETTING);
  await indexedDBClearData(INDEXEDDB_TABLES.DATA_STATISTIC_LIST);
  await indexedDBClearData(INDEXEDDB_TABLES.DATA_TABLE_BAR_STATISTIC_LIST);
  await indexedDBClearData(INDEXEDDB_TABLES.DATA_TEAM);
  await indexedDBClearData(INDEXEDDB_TABLES.INFOS);
  await indexedDBClearData(INDEXEDDB_TABLES.LOGIN);
  await indexedDBClearData(INDEXEDDB_TABLES.MEMBERS_DATA_CARDS);
  await indexedDBClearData(INDEXEDDB_TABLES.TRANSACTION_LIST);
  await indexedDBClearData(INDEXEDDB_TABLES.TRANSACTION_LIST_DETAIL);
};

export const removeDB = async () => {
  await clearLocalforage();
  await clearAllDataIndexeddb();
};
