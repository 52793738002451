import React from 'react';
import { useSubscription } from '@apollo/client';
import { ON_TRANSACTION_CREATE_OR_UPDATE } from '../../../../graphql/graphql_transaction_linkcy/transaction/subscription';
import { transactionClient } from '../../../../apolloClient';
import { useCreateOrUpdateTransactionCache } from '../../../customHooks/transaction/tools';
import {
  OnTransactionCreateOrUpdate,
  OnTransactionCreateOrUpdateVariables,
} from '../../../../graphql/graphql_transaction_linkcy/transaction/types/OnTransactionCreateOrUpdate';

const TransactionSub = (props: { ledgerLinkcyId: string }) => {
  const { ledgerLinkcyId } = props;
  const { updateOrCreate } = useCreateOrUpdateTransactionCache();
  useSubscription<
    OnTransactionCreateOrUpdate,
    OnTransactionCreateOrUpdateVariables
  >(ON_TRANSACTION_CREATE_OR_UPDATE, {
    variables: {
      ledgerLinkcyId,
    },
    client: transactionClient,
    onSubscriptionData: ({ subscriptionData: { data } }) => {
      if (data?.onTransactionCreateOrUpdate) {
        updateOrCreate(data.onTransactionCreateOrUpdate);
      }
    },
  });

  return <></>;
};

export default TransactionSub;
