import { useApolloClient } from '@apollo/client';
import { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { defaultErrorHandler } from '../../../utils/snackBarUtils';
import { fetcherWEB } from '../../../apolloClient';
import { API_ACCOUNT } from '../../../config';
import { ACCOUNT_NOTIFICATIONS } from '../../../graphql/graphql_account/notification/query';
import {
  AccountNotifications,
  AccountNotificationsVariables,
  AccountNotifications_getAccountNotifications,
} from '../../../graphql/graphql_account/notification/types/AccountNotifications';

export const useGetNotification = () => {
  const client = useApolloClient();
  const intl = useIntl();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<
    (AccountNotifications_getAccountNotifications | null)[]
  >([]);

  const fetchNotifications = useCallback(
    async (variables: AccountNotificationsVariables) => {
      return fetcherWEB<AccountNotificationsVariables, AccountNotifications>(
        ACCOUNT_NOTIFICATIONS,
        variables,
        API_ACCOUNT,
      )
        .then(async (result) => {
          setLoading(false);
          if (result?.getAccountNotifications) {
            setData(result.getAccountNotifications);
            return result.getAccountNotifications;
          }
        })
        .catch((error) => {
          setLoading(false);
          {
            false &&
              defaultErrorHandler(
                client,
                error,
                intl,
                'notification.error.getNotifications',
              );
          }
        });
    },
    [],
  );

  useEffect(() => {
    fetchNotifications({});
  }, [fetchNotifications]);

  return { fetchNotifications, loading, data };
};
