import { useLazyQuery } from '@apollo/client';
import { useAppDispatch } from '../../../redux/hook';
import { bankingClient } from '../../../apolloClient';
import { useEffect } from 'react';
import { _myInformation, MyInformationItem } from '../../../indexDB/data';
import {
  LedgerInfo,
  LedgerInfo_ledgerInfo,
} from '../../../graphql/graphql_banking/legder/types/LedgerInfo';
import { LEDGER_INFO } from '../../../graphql/graphql_banking/legder/query';
import { setMyLedger } from '../../../redux/ledger/ledgerSlice';

const useMyLedger = (synchronize?: boolean) => {
  const dispatch = useAppDispatch();

  const [getLedger, { loading, data }] = useLazyQuery<LedgerInfo>(LEDGER_INFO, {
    fetchPolicy: 'network-only',
    client: bankingClient,
    async onCompleted(data) {
      if (data?.ledgerInfo) {
        await _myInformation.setItem(
          MyInformationItem.MY_LEDGER,
          data.ledgerInfo,
        );
        await setData(data.ledgerInfo);
      }
    },
  });

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const cache = await _myInformation.getItem(MyInformationItem.MY_LEDGER);
    if (cache && !synchronize) {
      setData(cache as unknown as LedgerInfo_ledgerInfo);
      return;
    }
    getLedger();
  };

  const setData = async (data: LedgerInfo_ledgerInfo) => {
    dispatch(setMyLedger(data));
  };

  return { getLedger, loading, data };
};

export default useMyLedger;
