export default {
  maintenance: 'Sapheer is in maintenance, come back in a few minutes',
  'file.type': 'File type',
  'maintenance.title': `Maintenance in progress`,
  'maintenance.subtitle1': `We are improving our platform`,
  'maintenance.subtitle2': `Come back in a few minutes`,
  disconnect: 'Logout',
  setting: 'Setting',
  year: 'Year',
  title: 'Dashboard',
  'user.confirmation.deconnect': `Your session has expired, you will be logged out. Do you want to stay logged in?`,
  accept: 'Accept',
  stop: 'Stop',
  reset: 'Reset',
  'error.notEmptyField': 'This field cannot be empty.',
  'server.error': 'Server Error',
  'network.error': 'Network error',
  'other.error': 'Error',
  'error.uploadOnlyOneFile': 'Maximum file count reached',
  'error.uploadFileInvalid':
    'Invalid file type. Please choose an image or a pdf',
  'error.uploadTooLarge': 'File too large',
  'error.uploadTooSmall': 'File too small',
  'error.uploadFile': 'Error while uploading file',
  'error.invalidEmailAddress': 'Invalid email address',
  'error.getMe': 'Error while getting account informations.',
  'error.getRoleRights': 'Error while getting role and rights.',
  'error.nothingSelected': 'This select field cannot be empty.',
  'error.verifyPassword': 'Error while checking password.',
  'error.getCompanyInfo': 'Error while getting information about the company.',
  save: 'Save',
  validate: 'Validate',
  cancel: 'Cancel',
  edit: 'Edit',
  citizenship: 'Citizenship',
  dateBirth: 'Date of Birth',
  countryBirth: 'Country of Birth',
  cityBirth: 'City of Birth',
  legalName: 'Legal name',
  legalForm: 'Legal form',
  legalRegistrationNumber: 'SIRET',
  address: 'Address',
  postCode: 'Zip code',
  city: 'City',
  informations: 'Informations',
  contactInformation: 'Contact informations',
  'label.uploadImage': 'Upload image',
  'label.activation': 'Activation',
  'label.role': 'Role',
  'label.team': 'Team',
  'label.name': 'Name',
  'label.firstName': 'First Name',
  'label.emailAddress': 'Email Address',
  'label.member': 'Member',
  greeting: 'Hello, ',
  command: 'Command',
  previous: 'Previous',
  next: 'Next',
  finish: 'Finish',
  'label.directionTeam': 'Management',
  'label.marketing': 'Marketing',
  'label.finance': 'Finance',
  'label.informatique': 'IT',
  'label.commercial': 'Commercial',
  'label.admin': 'Admin',
  'label.contributor': 'Contributor',
  'label.accountant': 'Accountant',
  'label.none': 'FILTER',
  'label.virtual': 'Virtual',
  'label.physical': 'Physical',
  'label.activate': 'Activate',
  'message.yourOffer': 'Your offer',
  'label.numberOfLine': 'Number of lines',
  'label.of': 'of',
  '404':
    'The page you are looking for does not exist. Please contact the administrator.',
  '404.button': 'return to home',
  expirate:
    'It looks like your link has expired. Please renew your request by contacting the manager.',
  expenses: 'Expenses',
  recipes: 'Recipes',
  LAST_3_DAYS: '3 last days',
  LAST_7_DAYS: '7 last days',
  LAST_10_DAYS: '10 last days',
  LAST_15_DAYS: '15 last days',
  LAST_MONTH: 'Last month',
  LAST_2_MONTHS: '2 last months',
  LAST_3_MONTHS: '3 last months',
  member: 'Member',
  all: 'All',
  period: 'Period',
  category: 'Category',
  flux: 'Flux',
  incoming: 'Incoming',
  outgoing: 'Outgoing',
  femaleAll: 'All',
  activate: 'Activate',
  notActivate: 'Disable',
  none: 'None',
  femaleNone: 'None',
  alls: 'All',
  'text.providePassword':
    'You must provide your password to apply your changes.',
  sender: 'Sender:',
  nextTime: 'Next:',
  confirm: 'Confirm',
  close: 'Close',
  details: 'More',
  date: 'Date',
  download: 'Download',
  'search.noResult':
    "Oops, \n We haven't found any results that match your search.",
  logout: 'Logout',
  notValidatedCompany: `Your pro account is being validated within 48 hours. We will let you know as soon as it is finalized by our teams.
  Team Sapheer`,
  'error.fileNotSelected': 'Please select a file.',
  'error.unknownError':
    'An unknown problem has occurred, please try again later.',

  'build.content': 'Coming soon',
  'form.tel': 'Phone number',
  'phoneNumber.invalid':
    'Invalid phone number: The phone number must start with 00 or +',
  'phone.empty': 'The phone number field is required',

  'error.getUserFeature': `Verification user authorization error`,

  'feature.disabled': `Your account has been disabled. Please contact the team Sapheer`,
  'paiement.waiting': `Please waiting...`,

  startDate: 'Start date',
  endDate: 'End date',
  CANCELED: 'Canceled',
  VALIDATE: 'Validate',
  PENDING: 'Pending',
  'label.day': 'Day',
  'label.week': 'Week',
  'download.file.error': 'Error downloading file.',
  'account.can.t.generate.statement': `You do not have the right to generate the account statement`,
  'sca.content.1': `<text>Download</text> the <text>Linkcy Authenticator</text> app from the Apple Store or Play Store, then scan this <text>QR Code</text> or enter the <text>code</text> below.`,
  'sca.content.2': `<text>Refresh</text> your browser if the window does not disappear <text>after 20 seconds</text>.`,
  'sca.title': `Mandatory Validation`,
  'sca.valid.instruction': `Open the <text>Linkcy Authenticator</text> app to validate your <text>request</text>.`,
  'delete.sca': `Delete SCA`,
  'detele.sca.success': `SCA deleted successfully`,
  'delete.sca.error': `Error deleting SCA`,
  'delete.sca.confirmation': `Confirm deletion`,
};
