import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { InformationInfo } from '../../graphql/graphql_information/information/types/InformationInfo';

export const informationSlice = createSlice({
  name: 'information',
  initialState: {
    myInformation: {} as InformationInfo,
  },
  reducers: {
    setMyInformation: (state, action: PayloadAction<InformationInfo>) => {
      state.myInformation = action.payload;
    },
  },
});

export const { setMyInformation } = informationSlice.actions;

export default informationSlice.reducer;
