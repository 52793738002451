import gql from 'graphql-tag';

export const OFFERS_FRAGMENT = gql`
  fragment OffersInfo on Offer {
    id
    name
    virtualCard
    physicalCard
    payins
    payouts
    price
    limitPaymentMonth
    limitAtmWeek
    limitUser
  }
`;
