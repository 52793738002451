import Typography from '@material-ui/core/Typography';
import React from 'react';
import { useAppSelector } from '../../../redux/hook';

const MemberName = () => {
  const { connected } = useAppSelector(
    (state) => state.information.myInformation,
  );

  return (
    <Typography
      component="span"
      style={{ fontWeight: 600, whiteSpace: 'nowrap' }}
    >{`${connected?.firstName ?? connected?.lastName ?? ''}`}</Typography>
  );
};

export default MemberName;
