import { CARD_STATUS } from "../../../enums/card";
import { CardInfo } from "../../../graphql/graphql_card_linkcy/card/types/CardInfo";
import { CardItem, _cards, _company } from "../../../indexDB/data";
import { setAllCard, setListCard } from "../../../redux/card/cardSlice";
import { useAppDispatch, useAppSelector } from "../../../redux/hook";
import { addOrUpdateObject, removeObject } from "../../../utils/utils";

export const useCreateOrUpdateCardCache = () => {
  const { listCard, allCard } = useAppSelector((state) => state.card);
  const dispatch = useAppDispatch();

  const updateOrCreate = async (cardUpdate: any) => {
    const newList = addOrUpdateObject(listCard, cardUpdate);
    dispatch(setListCard(newList));
    await _cards.setItem(CardItem.LIST_CARD, newList);
  };

  const removeCardLinkcy = (cardRemove: CardInfo) => {
    const newList = removeObject(allCard, cardRemove);
    dispatch(setAllCard(newList));
  };

  const createOrUpdateCardLinkcy = (cardUpdate: CardInfo) => {
    if (
      cardUpdate.status === CARD_STATUS.CLOSED ||
      cardUpdate.status === CARD_STATUS.CLOSING ||
      cardUpdate.status === CARD_STATUS.FAILED
    ) {
      removeCardLinkcy(cardUpdate);
      return;
    }
    const newList = addOrUpdateObject(allCard, cardUpdate);
    dispatch(setAllCard(newList));
  };

  return { updateOrCreate, createOrUpdateCardLinkcy, removeCardLinkcy };
};
