import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { LastTransaction_lastTransaction } from "../../graphql/graphql_transaction/types/LastTransaction";
import { TransactionByCard_transactionByCard } from "../../graphql/graphql_transaction/types/TransactionByCard";
import { ListCategory_listCategory } from "../../graphql/graphql_transaction/types/ListCategory";
import { AllTransaction_allTransaction_content } from "../../graphql/graphql_transaction_linkcy/transaction/types/AllTransaction";
import { AllBeneficiary_allBeneficiary_content } from "../../graphql/graphql_transaction_linkcy/beneficiary/types/AllBeneficiary";
import { TransactionInfo } from "../../graphql/graphql_transaction_linkcy/transaction/types/TransactionInfo";

export interface ISetDetailsTransaction {
  transactionId: string;
  dataDetails: string;
}
export const transactionSlice = createSlice({
  name: "transaction",
  initialState: {
    transactionInformation: {} as TransactionInfo | null,
    canCreateTransaction: false,
    lastTransaction: [] as (AllTransaction_allTransaction_content | null)[],
    txInvalidated: [] as (AllTransaction_allTransaction_content | null)[],
    lastCardTransaction: [] as
      | (AllTransaction_allTransaction_content | null)[]
      | null,
    beneficiaries: [] as (AllBeneficiary_allBeneficiary_content | null)[],
    transactions: [] as Array<AllTransaction_allTransaction_content | null>,
    transactionByCard:
      [] as Array<AllTransaction_allTransaction_content | null>,
    categories: [] as (ListCategory_listCategory | null)[],
  },
  reducers: {
    setBeneficiaries: (
      state,
      action: PayloadAction<(AllBeneficiary_allBeneficiary_content | null)[]>,
    ) => {
      state.beneficiaries = action.payload;
    },
    setCanCreateTransaction: (state, action: PayloadAction<boolean>) => {
      state.canCreateTransaction = action.payload;
    },
    setSelectedTransaction: (
      state,
      action: PayloadAction<TransactionInfo | null>,
    ) => {
      state.transactionInformation = action.payload;
    },
    setLastTransaction: (
      state,
      action: PayloadAction<(AllTransaction_allTransaction_content | null)[]>,
    ) => {
      state.lastTransaction = action.payload;
    },
    setLastCardTransaction: (
      state,
      action: PayloadAction<
        (AllTransaction_allTransaction_content | null)[] | null
      >,
    ) => {
      state.lastCardTransaction = action.payload;
    },
    setTxInvalidated: (
      state,
      action: PayloadAction<(AllTransaction_allTransaction_content | null)[]>,
    ) => {
      state.txInvalidated = action.payload;
    },
    setTransactions: (
      state,
      action: PayloadAction<
        Array<AllTransaction_allTransaction_content | null>
      >,
    ) => {
      state.transactions = action.payload;
    },
    setTransactionsByCard: (
      state,
      action: PayloadAction<
        Array<AllTransaction_allTransaction_content | null>
      >,
    ) => {
      state.transactionByCard = action.payload;
    },
    setCategories: (
      state,
      action: PayloadAction<(ListCategory_listCategory | null)[]>,
    ) => {
      state.categories = action.payload;
    },
  },
});

export const {
  setBeneficiaries,
  setCanCreateTransaction,
  setSelectedTransaction,
  setLastTransaction,
  setLastCardTransaction,
  setTxInvalidated,
  setTransactions,
  setTransactionsByCard,
  setCategories,
} = transactionSlice.actions;

export default transactionSlice.reducer;
