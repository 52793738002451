import gql from 'graphql-tag';
import { CARD_FRAGMENT } from './fragment';

export const ON_CARD_CREATE_OR_UPDATE = gql`
  subscription OnCardCreateOrUpdate($ledgerLinkcyId: String) {
    onCardCreateOrUpdate(ledgerLinkcyId: $ledgerLinkcyId) {
      ...CardInfo
    }
  }
  ${CARD_FRAGMENT}
`;
