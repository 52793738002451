import gql from 'graphql-tag';

const INFORMATION_FRAGMENT = gql`
  fragment InformationInfo on Information {
    clearingDate
    clearingTransactionId
    conversionRate
    declineReason
    note
    originalAmount
    originalCurrency
    originalTransactionId
    paymentDate
    reference
    sequence
  }
`;

const DETAILS_FRAGMENT = gql`
  fragment DetailsInfo on Details {
    cardHolderName
    cardIssuer
    cardIssuerCountryCode
    cardScheme
    cardTruncPan
    cardType
    loadTransactionId
    paymentMethod
    targetLedgerId
    threeDsCheck
  }
`;

const CARD_DETAILS_FRAGMENT = gql`
  fragment CardDetailsInfo on CardDetails {
    cardPresent
    deviceFormFactor
    holderPresent
    inputMethod
    terminalAttendance
    token
    truncatedPan
  }
`;

const MERCHANT_FRAGMENT = gql`
  fragment MerchantInfo on Merchant {
    categoryCode
    city
    country
    description
    id
    name
  }
`;

const FEES_FRAGMENT = gql`
  fragment FeesInfo on Fees {
    acquiringCommission
    interchangeFee
    merchantCharge
    partnerFee
    schemeFee
  }
`;

const RECEIVER_FRAGMENT = gql`
  fragment ReceiverInfo on Receiver {
    accountNumber
    beneficiaryId
    bic
    endUserId
    iban
    ledgerFriendlyName
    ledgerId
    name
    originalName
    sortCode
    virtualLedgerFriendlyName
    virtualLedgerId
    cardFriendlyName
    cardId
  }
`;

const SENDER_FRAGMENT = gql`
  fragment SenderInfo on Sender {
    accountNumber
    bic
    cardFriendlyName
    cardId
    endUserId
    iban
    ledgerFriendlyName
    ledgerId
    name
    sortCode
    virtualLedgerFriendlyName
    virtualLedgerId
  }
`;

export const TRANSACTION_FRAGMENT = gql`
  fragment TransactionInfo on Transaction {
    amount
    creationDate
    currency
    id
    paymentType
    status
    type
    direction
    netAmount
    information {
      ...InformationInfo
    }
    receiver {
      ...ReceiverInfo
    }
    sender {
      ...SenderInfo
    }
    cardDetails {
      ...CardDetailsInfo
    }
    details {
      ...DetailsInfo
    }
    fees {
      ...FeesInfo
    }
    merchant {
      ...MerchantInfo
    }
  }
  ${INFORMATION_FRAGMENT}
  ${SENDER_FRAGMENT}
  ${RECEIVER_FRAGMENT}
  ${FEES_FRAGMENT}
  ${MERCHANT_FRAGMENT}
  ${DETAILS_FRAGMENT}
  ${CARD_DETAILS_FRAGMENT}
`;
