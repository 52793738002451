export default {
  'member.success': 'Votre mot de passe a été enregistré avec succès',
  'member.password.title': 'Création de mot de passe',
  'member.password.details': 'Veuillez saisir votre mot de passe',
  'invite.information': 'Vos informations',
  'invite.headTitle': 'Remplissez le formulaire pour créer votre compte',
  'invite.paragraph.1': "Vous avez été invité à réjoindre l'équipe ",
  'invite.paragraph.2': ' en tant que ',
  'invite.person': 'Mes informations légales',
  'invite.address': 'Mon adresse personnelle',
  'invite.person.attachement.type': "Type de pièce d'identité",
  'invite.person.vigilance.attachement': 'Vigilance complémentaire',
  'invite.person.attachement.file': "Justificatif de pièce d'identité",
  'invite.address.attachement.type': 'Type de justificatif de domiciliation',
  'invite.address.attachement.file': 'Justificatif de domicile',
  'invite.birthday': 'Date de naissance',
  'invite.form.documents.empty': 'Absence de document',
  'invite.form.field.empty': 'Champ vide',
  'invite.create.success': 'Informations sur dirigeant ajoutées avec succès',
  'invite.form.proof.empty': 'Veuillez choisir un document correspondant',
  'invite.createPerson.accept': `Veuillez confirmer la véracité des informations renseignées`,
  'invite.form.empty.name': 'Veuillez renseigner votre nom',
  'invite.form.empty.firstName': 'Veuillez renseigner votre prénom',
  'invite.form.nationality.empty': 'Veuillez renseigner votre citoyenneté',
  'invite.form.empty.identity2': 'Pièce d’identité complémentaire manquante',
  'invite.form.empty.birthday': 'Veuillez renseigner votre date de naissance',
  'invite.form.empty.placeOfBirthday':
    'Veuillez renseigner votre lieu de naissance',
  'invite.form.empty.birthCountry':
    'Veuillez renseigner votre pays de naissance',
  'invite.form.empty.birthPostCode':
    'Veuillez renseigner le code postal de votre ville de naissance',
  'invite.form.empty.address': 'Veuillez renseigner votre adresse ',
  'invite.form.empty.postCode': 'Veuillez renseigner votre code postal',
  'invite.form.empty.city': 'Veuillez renseigner votre ville',
  'invite.form.empty.identity':
    'Veuillez choisir un type de document pour votre identité',
  'invite.form.empty.addressProof':
    "Veuillez choisir un type de document pour l'adresse",
  'invite.form.empty.title': 'Veuillez renseigner votre genre',

  'invite.form.empty.phone': 'Veuillez renseigner votre numéro de téléphone',

  'invite.form.empty.type': "Veuillez choisir votre rôle dans l'entreprise",
  'invite.form.empty.identityFile': `Veuillez sélectionner un fichier pour justificatif de pièce d'identité`,
  'invite.form.empty.addressFile': `Veuillez sélectionner un fichier pour justificatif de domicile`,
  'manager.birthday.notAdult': `Vous devriez être une personne majeur pour pouvoir créer un compte.`,
  'invite.form.checkbox': `Je certifie sur l'honneur que toutes les informations renseignées sont correctes.`,
  'invite.form.agree': `Je certifie que je suis uniquement un résident(e) fiscal français.`,
  'invite.createPerson.error.agree': `Veuillez confirmer que vous ête un résident fiscal en France`,
  'invite.button.send': 'Envoyer',
  'member.message.delete': 'Voulez-vous vraiment supprimer ce membre ?',
  'invite.error.createPerson': `Erreur lors de l'enregistrement de vos informations. Veuillez réessayer ultérieurement.`,
  'invite.notAccept': `Veuillez cocher la confirmation de la véracité des informations renseignées`,
  'person.checkbox.usSpecifiedPerson':
    'Je certifie que je ne suis pas une US Person',
  'form.gender': 'Genre',

  'invite.form.empty.usperson': 'Cette case est obligatoire',
  'invite.form.isHost': `Je suis hébergé(e)`,
  'cin.recto': `Pièce d'identité`,
  'cin.verso': `Pièce d'identité Verso (Optionnelle)`,

  'host.host.identity': `Pièce d'identité de l'hébergeur`,
  'host.host.addressProof': `Justificatif de domicile de l'hébergeur`,
  'host.host.attestation': `Attestation hébergeur`,

  'host.host.enough.file': `Veuillez ajouter les 3 fichiers nécessaires en tant qu'hébergée`,

  'host.form.identityType': `Pièce d'identité de l'hébergeur`,
  'host.form.addressProof': `Justificatif de domicile de l'hébergeur`,
  'address.perso': 'Adresse personnelle',
  'identity.file.recto': `Pièce d'identité`,
  'identity.file.verso': `Pièce d'identité Verso (Optionnelle)`,
  'identity2.file.recto': `Vigilance complémentaire`,
  'identity2.file.verso': `Vigilance complémentaire Verso (Optionnelle)`,
  'identity3.file.recto': `Pièce d'identité de l'hébergeur`,
  'identity3.file.verso': `Pièce d'identité de l'hébergeur Verso (Optionnelle)`,

  'member.email.already.exists': `Cette adresse email existe déjà, veuillez la changer.`,
  'member.cannot.delete.owner': `Vous ne pouvez pas supprimer le représentant légal de l'entreprise.`,
  'address.street': `Adresse`,
  'address.postalCode': `Code postal`,
  'address.city': `Ville`,
  'address.isoCountryCode': `Pays`,
  'address.personal': `Adresse personnelle`,

  'member.address.agree': `Je certifie être uniquement résident(e) fiscal français`,
  'member.certify.information': `Je certifie sur l'honneur l'exactitude des informations`,
  'member.certify.usSpecifiedPerson': `Je certifie ne pas être “US Person”`,

  'member.form.citizenShip': 'Citoyenneté',
  'member.form.gender': 'Genre',

  'member.type.empty': `Veuillez choisir votre rôle dans l'entreprise`,
  'member.director.appointment.date.empty': `Veuillez renseigner votre date de nomination en tant que directeur`,
  'member.name.empty': `Veuillez renseigner votre nom`,
  'member.firstName.empty': `Veuillez renseigner votre prénom`,
  'member.nationality.empty': `Veuillez renseigner votre citoyenneté`,
  'member.usPerson.empty': `Cette case est obligatoire`,
  'member.gender.empty': `Veuillez renseigner votre genre`,
  'member.form.error.agree': `Veuillez certifier que vous êtes un résident fiscal en France.`,
  'member.error.accept': `Veuillez confirmer la véracité des informations renseignées.`,
  'member.phone.already.exists': `Cette numéro téléphone existe déjà, veuillez la changer.`,
  'member.phone.empty': `Veuillez renseigner votre numéro téléphone.`,
  'member.email.empty': `Veuillez renseigner votre adresse email.`,
  'member.doc.empty': `Veuillez remplir votre document`,
  'member.doc.type.empty': `Veuillez indiquer le type du document`,
};
