export default {
  CORPORATE_KYB_MUST_BE_VALIDATED:
    "Your account must be validated to perform a transfer.",
  LINKCY_STATUS_MUST_BE_VALIDATED:
    "Your account must be validated to perform a transfer.",
  END_USER_MUST_NOT_BE_SUSPENDED:
    "Your account has been suspended. You cannot perform this operation.",
  BANKING_STATUS_MUST_BE_VALIDATED:
    "Your account's banking status must be validated to perform a transfer.",
  LEDGER_MUST_BE_ACTIVE:
    "Your account has been suspended. You cannot perform this operation.",
  LEDGER_MUST_BE_PROVISIONED:
    "Your balance is insufficient to perform this transfer.",
  BENEFICIARY_MUST_BE_ACTIVE:
    "The beneficiary must be active to perform this transfer.",
  BENEFICIARY_MUST_BE_VALID:
    "The beneficiary must be valid to perform this transfer.",
  LEDGER_MUST_BE_UPGRADED:
    "Your account must be upgraded to perform a transfer.",
  TERMS_AND_CONDITIONS_NOT_ACCEPTED:
    "Terms and conditions must be accepted to perform a transfer.",
  BENEFICIARY_NOT_COMPATIBLE:
    "The selected beneficiary is not compatible with your account.",
  LEDGER_TRANSACTION_LIMIT_REACHED:
    "Your account's transaction limit has been reached.",
  LEDGER_TRANSACTION_DAILY_LIMIT_REACHED:
    "Your account's daily transaction limit has been reached.",
  LEDGER_TRANSACTION_MONTHLY_LIMIT_REACHED:
    "Your account's monthly transaction limit has been reached.",
};
