export default {
  "card.dialog.title": " Choose type of card :",
  "card.pile.locked": "Activate card ",
  "card.dialog.label.physic": "Physical card",
  "card.dialog.label.virtual": "Virtual card",
  "card.dialog.addNew.title": "Out package !",
  "card.dialog.addNew.paragraph": "You have reached the card creation limit.",
  "card.dialog.payement.amountToPay": "Amount to be paid",
  "card.dialog.payement.cardNumber": "Number of card",
  "card.payement.pay": "Pay",
  "card.codePin": "Pin Code",
  "card.selected": "Select a card to preview the information.",
  "card.confirmPin": "Confirm pin code",
  "card.oldPin": "Old pin code",
  "card.cardDialogue.physicalTitle": "Physical card",
  "card.cardAuth.title": "Card details",
  "card.cardAuth.subTitle": "Security",
  "card.cardPin.title": "PIN code",
  "card.cardAuth.paragraph":
    "For security reasons, you must provide a password in order to view in detail the PAN number and CVV of the card",
  "card.cardDialogue.virtualTitle": "Virtual card",
  "card.cardDialogue.type": "Card types",
  "card.cardDialogue.ownerTitle": "Owner",
  "card.cardDialogue.address": "Address",
  "card.cardDialogue.codeTitle": "Choose one code of 4 number",
  "card.cardDialogue.priceTitle": "Limit",
  "card.cardDialogue.member": "Member",
  "card.cardDialogue.categories": "Categories",
  "card.cardDialogue.cardType": "Type",
  "card.pin.notMatch": "Please confirm your pin code",
  "card.info.withDrawalLimit":
    " The maximum monthly cash withdrawal limit for this card is €1,000.00 over a period of 30 days from the date of activation of the card.",
  "card.info.paymentLimit":
    " The maximum monthly limit for payments is €20,000.00, over a period of 30 days from the date of activation of the card.",
  "card.virtual": "Virtual",
  "card.physical": "Physical",
  "mainCard.nocard":
    'You have not yet created a card. You can create physical or virtual cards for your company by clicking on the "Create a card" button.',
  "mainCard.label.addCard": "Create card",
  "mainCard.label.searchCard": "Search card",
  "mainCard.label.transaction": "Payment details",
  "mainCard.dialogueTitle": "Create card",
  "mainLabel.notCard": "No card yet",
  "card.modal.paiement": "Paiement",
  "card.modal.paiement.day": "Paiement / day",
  "card.modal.paiement.week": "Paiement / week",
  "card.modal.retrait": "Cash withdrawal",
  "card.paiement": "Paiement :",
  "card.retrait": "Cash withdrawal :",
  "card.retrait.pile": "Cash withdrawal",
  "card.foreign": "Foreigner",
  "card.online": "Online",
  "card.nfc": "No contact",
  "card.active": "Unlock",
  "card.details": "Details",
  "card.return": "Return",
  "card.add.nopayment": "Please set the card payment limit",
  "card.selected.categories": "Please choose a category",
  "card.add.nocashwithDrawal": "Please set the card cash withdrawal",
  "card.created.success": "Card created with success",
  "card.updated.success": "Card updated with success",
  "card.dialogue.cancel": "Cancel",
  "card.dialogue.create": "Create",
  "card.add.noMember": "Please choose one member",
  "card.add.noDeliveryAddress": "Please choose one address",
  "card.create.error": "Be sure that card owner have been validated",
  "card.rigthPanel.description": "Select a card to see payment details",
  "mainTeam.error.notAllowAddMember": "no longer allows you to add a member.",
  "card.error.notAllowAddPhysical": `no longer allows you to create new physical card.`,
  "card.error.notAllowAddVirtual": `no longer allows you to create new virtual card.`,
  "card.plus.error.exceed": "You have reached the maximum number of card",
  "card.pin.empty": "Pin code empty",
  "card.updatePin.success": "Pin code updated with success",
  "card.updatePin.error": "Update PIN code erreur",
  "card.last.card": "Latest payments",
  "card.cardDetails.title": "See details",
  "card.display.pin": "View PIN code",
  "card.dialogTitle.checkPassword": "Check password",
  "card.filter.forms": "Forms",
  "card.filter.title": "Card Filter",
  "card.filter.subtitle": "Filter your Cards",
  "card.filter.paragraph": "See your card with your criteria and parameter",
  "card.image.error": "Error when getting information about card",
  "card.display.pin.error": `Error displaying the card pin code`,
  "card.beneficiary.name": `Beneficiary `,
  "card.dialog.text.received": `You will receive your <text>card</text> within a <text>maximum</text> of 5 business days at your <text>company</text> address.`,
  "card.instruction.creation.dialog":
    "Ouvrir application <text>Linkcy Authenticator</text> pour valider la création de <text>carte</text>.",

  ACTIVATION_CARD_ERROR: `Card activation error`,
  CARD_3DS_ERRORS: " 3DS Card error",
  TREEZOR_CREATION_CARD_ERROR: "Error when creating card",
  ACCOUNT_NEED_TO_VALIDATE: `Your account need to be validate`,
  TREEZOR_CONVERSION_CARD_ERROR: `Error when converting virtual card to physical card`,
  PAIEMENT_OR_WITHDRAW_VALUE_ERROR: `The chosen value does not correspond to your offer.`,
  CARD_NOT_ACTIVE: `Please activate the card`,

  "card.not.treezorCardId": `The card don't have an ID from creator`,

  "card.currentPin": "Current PIN",
  "currentPin.empty": `Please insert your current PIN`,

  "card.getPin.error": "Error when getting the current pin code",

  "card.created.error": "Error when creating the card`",
  "card.created.can.not.created": `You do not have the right to create a bank card`,
  "card.created.need.validate": `Ensure card owner has been validated`,
  "card.created.paiement.withdrawal.error": `Payment or withdrawal is greater than authorized`,
  "card.created.pin.error": `The Pin codes inserted are different`,
  "card.created.error.treezor": `Error occurred when creating the card at treezor`,
  "card.created.covertion.error": `Error occurred while converting card`,
  "card.account.can.t.manage": `You do not have the right to modify a card`,
  "card.active.success": `Card activated successfully.`,
  "card.created.insufficient.amount": `You don’t have enough money to create this card`,
  "card.update.success": `Card update successfully`,
  "card.input.required": `Please contact Sapheer customer service, as required user information is missing during the card creation.`,
  "card.company.limited": `You do not have the right to update this card because your account is limited.`,
  "card.friendly.name.already.exists":
    " This card name is already in use. Please choose another one.",

  "card.title.friendlyName": "Card Name",
  "card.input.friendlyName": "Name",
  "card.title.nameOnCard": "Name printed on the card",
  "card.input.nameOnCard": "Name on Card",
  "card.title.max.spend": "Maximum Spend",
  "card.input.daily": "Daily",
  "card.input.weekly": "Weekly",
  "card.input.monthly": "Monthly",
  "card.title.delivery.method": "Delivery Choice",
  "card.input.delivery.method": "Delivery",

  "card.add.delivery.method.empty": "Please provide the delivery method.",
  "card.add.weekly.spend.empty": "Please provide your maximum weekly spend.",
  "card.add.daily.spend.empty": "Please provide your maximum daily spend.",
  "card.add.monthly.spend.empty": "Please provide your maximum monthly spend.",
  "card.add.friendly.name.empty": "Please provide the card name.",
  "card.add.name.on.card.empty":
    "Please provide the name to be printed on the card.",
  "card.add.name.on.card.invalid": `The name to be printed on the card is not valid.`,

  "french.mail": "French Mail",
  "french.mail.tracking": "French Mail Tracking",
  "international.mail": "International Mail",

  "card.update.error": `Card update has failed.`,
  "card.last.4.digits.error": `Please enter the last 4 digits of your card.`,
  "card.activation.title": `Card Activation`,
  "card.active.button": "Activate",

  "trucated.pan.not.correspond":
    "The numbers you entered do not match the last 4 digits of the card.",
};
