import React from 'react';
import { useSubscription } from '@apollo/client';
import { ON_BALANCE_UPDATE } from '../../../../graphql/graphql_banking/legder/subscription';
import {
  OnBalanceUpdate,
  OnBalanceUpdateVariables,
} from '../../../../graphql/graphql_banking/legder/types/OnBalanceUpdate';
import { bankingClient } from '../../../../apolloClient';
import { _myInformation, MyInformationItem } from '../../../../indexDB/data';
import { useAppDispatch } from '../../../../redux/hook';
import { setMyLedger } from '../../../../redux/ledger/ledgerSlice';

const LedgerSub = (props: { corporateLinkcyId: string }) => {
  const { corporateLinkcyId } = props;
  const dispatch = useAppDispatch();

  useSubscription<OnBalanceUpdate, OnBalanceUpdateVariables>(
    ON_BALANCE_UPDATE,
    {
      variables: {
        corporateLinkcyId,
      },
      client: bankingClient,
      onSubscriptionData: async ({ subscriptionData: { data } }) => {
        if (data?.onBalanceUpdate) {
          dispatch(setMyLedger(data.onBalanceUpdate));
          await _myInformation.setItem(
            MyInformationItem.MY_LEDGER,
            data.onBalanceUpdate,
          );
        }
      },
    },
  );

  return <></>;
};

export default LedgerSub;
