export default {
  LEDGER_NOT_FOUND:
    "Le compte demandé est introuvable. Veuillez vérifier les informations fournies.",
  CARD_PROFILE_NOT_ACTIVE:
    "Le profil que vous avez sélectionné n'est pas actif. Veuillez choisir un autre profil.",
  CARD_PROFILE_NOT_FOUND:
    "Le profil que vous avez sélectionné est introuvable. Veuillez choisir un autre profil..",
  CARD_PROGRAM_NOT_COMPATIBLE_END_USER:
    "Le programme de carte sélectionné n'est pas compatible avec votre compte.",
  DELIVERY_METHOD_REQUIRED:
    "Une méthode de livraison est requise. Veuillez sélectionner une méthode de livraison.",
  FRIENDLY_NAME_ALREADY_TAKEN:
    "Le nom de la carte que vous avez choisi existe déjà. Veuillez en changer, s'il vous plaît.",
  CARD_RULE_NOT_UNIQUE:
    "La règle de carte spécifiée n'est pas unique. Veuillez vérifier et modifier la règle.",
  INVALID_DELIVERY_METHOD:
    "La méthode de livraison sélectionnée est invalide. Veuillez en choisir une autre.",
  CARD_PROFILE_NOT_COMPATIBLE_PARTNER:
    "Le profil de carte sélectionné n'est pas compatible avec Sapheer.",
  CORPORATE_KYB_MUST_BE_VALIDATED:
    "Votre compte doit être validé avant de pouvoir créer une carte.",
  LINKCY_STATUS_MUST_BE_VALIDATED:
    "Votre compte doit être validé pour effectuer cette opération.",
  END_USER_MUST_NOT_BE_SUSPENDED:
    "Votre compte a été suspendu. Vous ne pouvez pas effectuer cette opération.",
  BANKING_STATUS_MUST_BE_VALIDATED:
    "Votre compte bancaire doit être validé pour effectuer cette opération.",
  CARD_PROGRAM_NOT_COMPATIBLE_LEDGER:
    "Le programme de carte sélectionné n'est pas compatible avec le compte.",
  TERMS_AND_CONDITIONS_NOT_ACCEPTED:
    "Les termes et conditions doivent être acceptés avant de pouvoir créer une carte.",
  LEDGER_MUST_BE_ACTIVE: "Le compte doit être actif pour créer une carte.",
  INVALID_CARD_PROGRAM_TYPE:
    "Le type de programme de carte sélectionné est invalide. Veuillez en choisir un autre.",
  LEDGER_MUST_NOT_BE_SUSPENDED:
    "Votre compte a été suspendu. Vous ne pouvez pas créer de carte.",
  LEDGER_MUST_NOT_BE_VIRTUAL:
    "Le compte ne doit pas être virtuel pour effectuer cette action.",
  INVALID_CARD_PROGRAM_COUNTRY:
    "Le programme de carte que vous avez sélectionné n'est pas disponible dans votre pays. Veuillez en choisir un autre.",
  UNSUPPORTED_DELIVERY_METHOD:
    "La méthode de livraison sélectionnée n'est pas prise en charge. Veuillez en choisir une autre.",
  RESERVED_BALANCE_MUST_BE_EMPTY_WHEN_CREATING_FIRST_CARD:
    "Une autre opération est en cours de traitement sur votre compte. Veuillez réessayer plus tard pour créer votre première carte",
  OTHER_CARD_BEING_CREATED_FOR_SAME_LEDGER:
    "Une autre carte est actuellement en cours de création. Veuillez réessayer plus tard.",

  CARD_MUST_BE_SUSPENDED_OR_AWAITING_ACTIVATION:
    "La carte doit être suspendue ou en attente d'activation pour effectuer cette opération.",
  INSUFFICIENT_RIGHTS:
    "Vous n'avez pas les droits suffisants pour effectuer cette opération.",
  INVALID_PAN:
    "Le numéro PAN fourni est invalide. Veuillez vérifier les informations.",
  CARD_MUST_BE_ACTIVE:
    "La carte doit être active pour effectuer cette opération.",
  OTHER_STATUS_REASON_MUST_HAVE_A_DESCRIPTION:
    "Une description est requise pour la raison de ce statut.",
  CARD_MUST_BE_PHYSICAL:
    "La carte doit être physique pour effectuer cette opération.",
  CARD_NOT_FOUND:
    "La carte demandée est introuvable. Veuillez vérifier les informations fournies.",
};
