export enum TRANSACTION_MODALITY {
  IN = 0,
  OUT = 1,
}
export enum TRANSACTION_TYPE {
  INTER_LEDGER = "INTER_LEDGER",
  CARD_AUTHORIZATION = "CARD_AUTHORIZATION",
  CARD_CLEARING = "CARD_CLEARING",
  SEPA_DIRECT_DEBIT = "SEPA_DIRECT_DEBIT",
  ACQUIRING = "ACQUIRING",
  FX = "FX",
  SEPA_CREDIT_TRANSFER = "SEPA_CREDIT_TRANSFER",
  UK_CREDIT_TRANSFER = "UK_CREDIT_TRANSFER",
  UNKNOWN = "UNKNOWN",
}

export enum TRANSACTION_STATUS {
  APPROVED = "APPROVED",
  DECLINED = "DECLINED",
  RELEASED = "RELEASED",
  REVERSED = "REVERSED",
  PENDING = "PENDING",
  PENDING_REVIEW = "PENDING_REVIEW",
  UNKNOWN = "UNKNOWN",
  ABORTED = "ABORTED",
}

export enum PAYMENT_TYPES {
  MONEY_TRANSFER = "MONEY_TRANSFER",
  TECH_MVC_CREATION = "TECH_MVC_CREATION",
  TECH_TRANSACTION = "TECH_TRANSACTION",
  TECH_VIRTUAL_TRANSFER = "TECH_VIRTUAL_TRANSFER",
  ACCOUNT_LOAD = "ACCOUNT_LOAD",
  PAYMENT = "PAYMENT",
  ACCOUNT_FUNDING = "ACCOUNT_FUNDING",
  ACCOUNT_TRANSFER = "ACCOUNT_TRANSFER",
  ACCOUNT_VERIFICATION = "ACCOUNT_VERIFICATION",
  ADJUSTMENT_CREDIT = "ADJUSTMENT_CREDIT",
  ADJUSTMENT_DEBIT = "ADJUSTMENT_DEBIT",
  CASH_ADVANCE = "CASH_ADVANCE",
  CARD_LOAD = "CARD_LOAD",
  CARD_UNLOAD = "CARD_UNLOAD",
  CHECK_DEPOSIT = "CHECK_DEPOSIT",
  DEPOSIT = "DEPOSIT",
  FEE_COLLECTION = "FEE_COLLECTION",
  ORIGINAL_CREDIT = "ORIGINAL_CREDIT",
  PAYMENT_TRANSACTION = "PAYMENT_TRANSACTION",
  PURCHASE = "PURCHASE",
  PURCHASE_RETURN_OR_REFUND = "PURCHASE_RETURN_OR_REFUND",
  PURCHASE_WITH_CASHBACK = "PURCHASE_WITH_CASHBACK",
  QUASI_CASH = "QUASI_CASH",
  UNIQUE_MCC_TRANSACTION = "UNIQUE_MCC_TRANSACTION",
  WITHDRAWAL = "WITHDRAWAL",
  TECH_CARD_SETTLEMENT = "TECH_CARD_SETTLEMENT",
  SCT_RECEIVE = "SCT_RECEIVE",
  SCT_INST_RECEIVE = "SCT_INST_RECEIVE",
  SCT_SEND = "SCT_SEND",
  SCT_INST_SEND = "SCT_INST_SEND",
  SDD_SEND = "SDD_SEND",
  SDD_RECEIVE = "SDD_RECEIVE",
  BACS_RECEIVE = "BACS_RECEIVE",
  FASTER_PAYMENT_RECEIVE = "FASTER_PAYMENT_RECEIVE",
  BACS_SEND = "BACS_SEND",
  FASTER_PAYMENT_SEND = "FASTER_PAYMENT_SEND",
  GLOBAL_SWIFT = "GLOBAL_SWIFT",
  UNKNOWN = "UNKNOWN",
}
