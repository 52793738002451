export const API_URL = process.env.REACT_APP_API_URL;
export const API_V2_URL = process.env.REACT_APP_API_V2_URL;
export const API_TRANSACTION = process.env.REACT_APP_API_TRANSACTION_URL;
export const API_BANKING = process.env.REACT_APP_API_BANKING_URL;
export const API_ACCOUNT = process.env.REACT_APP_API_ACCOUNT_URL;
export const API_CARD = process.env.REACT_APP_API_CARD_URL;

export const ONBOARDING_URL =
  process.env.REACT_APP_ONBOARDING_URL ??
  "https://onboarding-linkcy-staging.hairun-technology.com";
export const APP_WS_URL = process.env.REACT_APP_WS_URL;
export const HIPAY_USERNAME = process.env.REACT_APP_HIPAY_USERNAME;
export const HIPAY_PASSWORD = process.env.REACT_APP_HIPAY_PASSWORD;
export const HIPAY_ENVIRONMENT = process.env.REACT_APP_HIPAY_ENVIRONMENT;
export const HIPAY_LANG = process.env.REACT_APP_HIPAY_LANG;

export const PRIVACY_POLICY_URL =
  process.env.REACT_APP_PRIVACY_POLICY_URL ??
  `https://sapheer.co/wp-content/uploads/2024/07/Privacy-Policy-Sapheer.pdf`;
export const CONDITION_SAPHEER_LINK =
  process.env.REACT_APP_CONDITION_SAPHEER_LINK ??
  `https://sapheer.co/wp-content/uploads/2024/07/CGS-Sapheer-LinkCy-Paynovate.pdf`;
export const CONDITION_LINKCY_LINK =
  process.env.REACT_APP_CONDITION_LINKCY_LINK ??
  `https://sapheer.co/wp-content/uploads/2024/07/GTCU_TC-LinkCy-x-PNVT_Accounts_Corporates_Cards.pdf`;

export const LINK_PLAY_STORE_LINKCY_AUTENTICATOR =
  process.env.REACT_APP_ANDROID_LINKCY_AUTENTICATOR_LINK ??
  `https://play.google.com/store/apps/details?id=io.linkcy.linkcy_sca_app&hl=fr`;
export const LINK_APPLE_STORE_LINKCY_AUTENTICATOR =
  process.env.REACT_APP_IOS_LINKCY_AUTENTICATOR_LINK ??
  `https://apps.apple.com/fr/app/linkcy-authenticator/id6478786933`;

export const CONTACT_URL = process.env.REACT_APP_CONTACT_URL;

export const CLOUDINARY_NAME = process.env.REACT_APP_CLOUDINARY_NAME;
export const CLOUDINARY_UPLOAD_PRESET =
  process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESET;

export const BO_URL = process.env.REACT_APP_BO_URL;
