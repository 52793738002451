export default {
  'member.success': 'your password has been saved with success',
  'member.password.title': 'Create password',
  'member.password.details': 'Please insert your password',
  'invite.information': 'Your informations',
  'invite.headTitle': 'Fill in the form to create your account',
  'invite.paragraph.1': "You've been invited to join the team ",
  'invite.paragraph.2': ' as ',
  'invite.person': 'My legal information',
  'invite.address': 'My home address',
  'invite.person.attachement.type': 'Type of Identity',
  'invite.person.attachement.file': 'Proof of identity document',
  'invite.person.vigilance.attachement': 'Vigilance complémentarity',
  'invite.form.empty.identity2': 'Vigilance complémentarity empty',
  'invite.address.attachement.type': 'Type of direct debit receipt',
  'invite.address.attachement.file': 'Proof of address',
  'invite.birthday': 'Birthday',
  'invite.form.documents.empty': 'Document empty',
  'invite.form.field.empty': 'Field empty',
  'invite.create.success': 'Manager create with success',
  'invite.form.proof.empty': 'Choose document type proof',
  'invite.createPerson.accept':
    'Please certify the accuracy of filled informations.',
  'invite.form.empty.name': 'Please set your name',
  'invite.form.empty.firstName': 'Please set your firstname ',

  'invite.form.empty.phone': 'Please set your phone number',
  'person.checkbox.usSpecifiedPerson': 'I certify that I am not a US Person',
  'invite.form.nationality.empty': 'Please set your nationality',
  'invite.form.empty.birthday': 'Please set your birthday',
  'invite.form.empty.placeOfBirthday': 'Please set your place of birth',
  'invite.form.empty.birthCountry': 'Please set you birth country',
  'invite.form.empty.birthPostCode': 'Please set your birth post code',
  'invite.form.empty.address': 'Please set your address',
  'invite.form.empty.postCode': 'Please set your post code',
  'invite.form.empty.city': 'Please set your city',
  'invite.form.empty.identity': 'Please set your identity document type',
  'invite.form.empty.addressProof': 'Please set your address document type',
  'invite.form.empty.type': 'Please choose your role in the company',
  'invite.form.empty.identityFile': `Please select an identity file proof.`,
  'invite.form.empty.addressFile': `Please select an address file proof.`,
  'invite.form.checkbox':
    'I certify on my honor that all filled informations are correct.',
  'invite.form.agree': `I certify that I am only a French tax resident.`,
  'invite.createPerson.error.agree': `Please confirm that you are a tax resident in France.`,
  'manager.birthday.notAdult': `You should be an adult person to be able to create an account.`,
  'invite.button.send': 'Send',
  'member.message.delete': 'Do you really want to delete this member?',
  'invite.error.createPerson':
    'Error while saving your informations, please try later.',
  'invite.notAccept': `Please check the accuracy of filled informations`,
  'form.gender': 'Gender',

  'invite.form.empty.usperson': 'This checkbox is required',
  'invite.form.isHost': `I am hosted`,
  'cin.recto': `Identity`,
  'cin.verso': `Identity Verso(Optional)`,

  'host.host.identity': `Identity card of the hosting provider`,
  'host.host.addressProof': `Proof of address of the hosting`,
  'host.host.attestation': `Hosting certificate`,

  'host.host.enough.file': `Please add the 3 necessary files as hosted`,

  'host.form.identityType': `Host identity type`,
  'host.form.addressProof': `Host address proof`,
  'address.perso': 'Personnal address',

  'identity.file.recto': `Identity document`,
  'identity.file.verso': `Identity document Verso (Optional)`,
  'identity2.file.recto': `Additional Vigilance`,
  'identity2.file.verso': `Additional Vigilance Verso (Optional)`,
  'identity3.file.recto': `Host identity document`,
  'identity3.file.verso': `Host identity document Verso (Optional)`,

  'member.email.already.exists': `This email address already exists, please change.`,
  'member.cannot.delete.owner': `You cannot remove the legal representative of the company.`,

  'address.street': `Address`,
  'address.postalCode': `Postal code`,
  'address.city': `City`,
  'address.isoCountryCode': `Country`,
  'address.personal': `Home address`,

  'member.address.agree': `I certify that I am only a French tax resident`,
  'member.certify.information': `I certify on my honor the accuracy of the information`,
  'member.certify.usSpecifiedPerson': `I certify that I am not a "US Person"`,

  'member.form.citizenShip': 'Citizenship',
  'member.form.gender': 'Gender',

  'member.type.empty': `Please select your role in the company`,
  'member.director.appointment.date.empty': `Please enter your appointment date as a director`,
  'member.name.empty': `Please enter your last name`,
  'member.firstName.empty': `Please enter your first name`,
  'member.nationality.empty': `Please enter your citizenship`,
  'member.usPerson.empty': `This field is required`,
  'member.gender.empty': `Please enter your gender`,
  'member.form.error.agree': `Please certify that you are a tax resident in French.`,
  'member.error.accept': `Please certify the accuracy of filled informations.`,
  'member.phone.already.exists': `This phone number already exists, please change it.`,
  'member.phone.empty': `Please enter your telephone number.`,
  'member.email.empty': `Please enter your email address.`,
  'member.doc.empty': `Please fill out your document.`,
  'member.doc.type.empty': `Please specify the type of document`,
};
